import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import Loading from "../../components/Loading";
import { Button } from "@material-ui/core";
import { useQuery } from "react-query";
import api from "../../services/api";

export default function ValidarRelatorio() {
  const history = useHistory();
  const token = history.location.pathname.split("/")[2];

  const { isFetching, data } = useQuery({
    queryFn: async () => {
      return await api.get(`report/customer-validate-report/${token}`);
    },
    queryKey: ["validarRelatorio"],
    refecthOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    refetchInterval: false,
    enabled: !!token,
    retry: false
  });

  return (
    <>
      {isFetching ? <Loading /> : null}
      <div className="login-container">
        <div className="container h-100">
          <div className={"row h-100"}>
            <div
              className={
                "d-flex justify-content-md-end justify-content-sm-center align-items-center h-100"
              }
            >
              <div
                className={"d-flex flex-column shadow rounded p-4"}
                style={{ background: "#fefefe" }}
              >
                <span style={{ fontSize: 16 }}>{data?.data?.message}</span>
                <Button style={{ alignSelf: "start" }}>
                  <a href="/">Voltar para o sistema.</a>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
