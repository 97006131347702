import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import React, { useState } from "react";
import { Button } from "primereact/button";
import { useServiceOrder } from "../../contexts/ServiceOrderContext";

export default function ServiceOrderFilters({
  isVisible = true,
  onHide = () => {},
  setMultiCompanies,
  companies
}) {
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const { getFromApi } = useServiceOrder();

  const setValues = () => {
    setMultiCompanies(selectedCompanies);
    onHide();
    getFromApi({ companies: selectedCompanies });
  };

  const footer = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          onHide();
          setSelectedCompanies([]);
        }}
      />
      <Button
        label={"Aplicar"}
        icon="pi pi-check"
        className="p-button-text"
        onClick={setValues}
      />
    </React.Fragment>
  );

  const getOptions = (array) => {
    return array.map((item) => {
      return {
        label: item.name,
        value: item.id
      };
    });
  };

  return (
    <Dialog
      header={"Filtros"}
      visible={isVisible}
      style={{ width: "50vw" }}
      footer={footer}
      onHide={() => {
        onHide();
        setSelectedCompanies([]);
      }}
    >
      <p style={{ fontWeight: "bold", fontSize: "18px" }}>
        Selecione as empresas
      </p>
      <MultiSelect
        value={selectedCompanies}
        style={{ width: "100%" }}
        options={getOptions(companies)}
        display="chip"
        filter
        onChange={(e) => setSelectedCompanies(e.value)}
      />
    </Dialog>
  );
}
