import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const DeleteDialog = ({
  visible,
  onHide,
  onConfirm,
  message = "Tem certeza que deseja excluir?",
  header = "Confirmar Exclusão",
  confirmLabel = "Excluir",
  cancelLabel = "Cancelar"
}) => {
  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header={header}
      modal
      onHide={onHide}
      footer={
        <>
          <Button
            label={cancelLabel}
            icon="pi pi-times"
            onClick={onHide}
            className="p-button-text"
          />
          <Button
            label={confirmLabel}
            icon="pi pi-check"
            onClick={onConfirm}
            className="p-button-danger"
          />
        </>
      }
    >
      <div className="confirmation-content">
        <span>{message}</span>
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
