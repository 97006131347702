import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Fade } from "@material-ui/core";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { useForm, Controller } from "react-hook-form";
import * as z from "zod";
import Input from "../Form/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import api from "../../services/api";

const schema = z.object({
  comments: z.string()
});

const ChangeObsPreventive = ({
  currentPreventive,
  onHide = () => {},
  visible = true,
  setLoading,
  getPreventivas
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch
  } = useForm({
    resolver: zodResolver(schema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    shouldFocusError: false
  });

  const [toastOptions, setToastOptions] = useState({});
  const toast = useRef(null);

  useEffect(() => {
    if (currentPreventive) {
      setValue("comments", currentPreventive?.comments);
    }
  }, [currentPreventive]);

  const hideForm = () => {
    onHide();
  };
  const submitForm = async (data) => {
    if (!currentPreventive) {
      return;
    }
    const isGLP =
      currentPreventive.preventive_type === `EmpilhadeiraCombustao` ||
      currentPreventive.preventive_type === `EmpilhadeiraEletricaDeContrapeso`;
    setLoading(true);
    await api.put(`/preventive/update-observation/${currentPreventive.id}`, {
      report_type: isGLP ? "preventive_combustion" : "preventive",
      comments: data.comments
    });
    setLoading(false);
    toast.current.show({
      severity: "success",
      summary: "",
      detail: "Observações alteradas com sucesso.",
      life: 5000
    });
    onHide();
    getPreventivas();
  };

  const footer = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          onHide();
        }}
      />
      <Button
        label={"Salvar"}
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleSubmit((data) => submitForm(data))}
      />
    </React.Fragment>
  );

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        style={{ marginTop: 60, width: "80rem" }}
        className="p-fluid"
        modal
        onHide={hideForm}
        header={"Alterar observação da preventiva"}
        footer={footer}
      >
        <form action="">
          <Input
            error={errors.comments?.message}
            name={"comments"}
            label={"Observações"}
            register={register}
          />
        </form>
      </Dialog>
    </>
  );
};

export default ChangeObsPreventive;
