import React from "react";

import OrcamentoProvider from "./index";

export const withOrcamentoContext = (WrappedComponent) => (props) =>
  (
    <OrcamentoProvider>
      <WrappedComponent {...props} />
    </OrcamentoProvider>
  );
