import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import rejectImage from "../../../assets/rejectImage.png";
import verifyImage from "../../../assets/verifyImage.png";
import CreateCompanyEmailsForm from "../../../components/CreateCompanyEmailsForm";
import CreateEmpresaForm from "../../../components/CreateEmpresaForm";
import RowsDropdown from "../../../components/RowsDropdown";
import { useAuth } from "../../../contexts/AuthContext";
import api from "../../../services/api";
import { emailIsValid } from "../../../utils/validations";

const Table = () => {
  const { owner_company, access_type } = useAuth();

  const [rows, setRows] = useState(10);

  const [callCepApi, setCallCepApi] = useState(true);

  const [addressLink, setAddressLink] = useState("");

  const [productDialog, setProductDialog] = useState(false);
  const [singleCompany, setSingleCompany] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [formError, setError] = useState("");

  const toast = useRef(null);
  const dt = useRef(null);
  const [companies, setCompanies] = useState([]);
  const [counter, setCounter] = useState([]);
  const [search, setSearch] = useState("");
  const [is_active, setIsActive] = useState(1);
  const [page, setPage] = useState(1);
  const [ordenado, setOrdenado] = useState("");

  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("name");
  const ordenar = (sortField, sortOrder) => {
    setSortOrder(sortOrder);
    setSortField(sortField);
    setOrdenado(sortField);
  };

  useEffect(() => {
    getCompanies().then(() => setLoading(false));
  }, [page, search, ordenado, sortOrder, access_type, rows, is_active]);

  const getCompanies = async () => {
    setLoading(true);
    if (access_type === "Admin") {
      await api
        .get(
          `companies?page=${page}&search=${search}&orderBy=${ordenado}&order=${sortOrder}&rows=${rows}&is_active=${is_active}`
        )
        .then((response) => {
          setCompanies(response.data.data);
          setCounter(response.data.total);
        })
        .finally(() => setLoading(false));
    }
  };

  const getSingleCompany = async (id) => {
    const response = await api.get("companies/show/" + id);
    setSingleCompany(response.data);
    setName(response.data.name);
    setTelefone(response.data.phone);
    setEmail(response.data.email);
    setNomeContato(response.data.contact_name);
    setCNPJ(response.data.cnpj);
    setNomeFantasia(response.data.trading_name);
    setAddressLink(response.data.address_link);

    const address = response.data?.address;
    setCidade(address?.city);
    setPais(address?.country);
    setCep(address?.postcode);
    setEstado(address?.state);
    setRua(address?.street_name);
    setNumero(address?.number);
    setBairro(address?.district);

    setIsNew(false);

    setLoading(false);
  };

  const unsetData = () => {
    const unsetData = [
      setName,
      setAddress,
      setCNPJ,
      setNomeContato,
      setTelefone,
      setEmail,
      setCep,
      setPais,
      setCidade,
      setEstado,
      setRua,
      setNumero,
      setNomeFantasia,
      setBairro,
      setAddressLink
    ];

    unsetData.forEach((data) => data(""));

    setProductDialog(false);
  };

  const openNew = (rowData) => {
    document.body.style.position = "fixed";

    setProductDialog(true);
  };

  const hideDialog = () => {
    document.body.style.position = null;
    setError("");
    unsetData();
  };

  const saveProduct = () => {
    setProductDialog(false);
  };

  const exportCSV = async () => {
    setLoading(true);

    api
      .get("/companies/export-excel", {
        responseType: "blob"
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "empresas-" + new Date().getTime() + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Nova"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => {
            openNew();
            setIsNew(true);
          }}
        />
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exportar"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const deactivateCompany = async () => {
    setDeleteDialog(false);
    await changeCompanyStatus("deactivate");
  };

  const changeCompanyStatus = async (route) => {
    await api.put(`/companies/${route}/${singleCompany.id}`);

    let status = "ativada";
    if (route === "deactivate") {
      status = "inativada";
    }

    try {
      toast.current.show({
        severity: "success",
        summary: "",
        detail: `Empresa ${status} com sucesso.`,
        life: 3000
      });
      await getCompanies();
    } catch (e) {
      if (e && e.request && e.request.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "",
          detail: "Você não tem autorização para realizar essa operação.",
          life: 3000
        });
        return;
      }
      toast.current.show({
        severity: "error",
        summary: "",
        detail: "Ocorreu um erro, contate um administrador.",
        life: 3000
      });
    }
  };

  const [name, setName] = useState("");
  let [address, setAddress] = useState("");
  const [cnpj, setCNPJ] = useState("");

  const [nomeContato, setNomeContato] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");

  const [cep, setCep] = useState("");
  const [pais, setPais] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [rua, setRua] = useState("");
  const [numero, setNumero] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [bairro, setBairro] = useState("");
  const [emails, setEmails] = useState([]);
  const [__is_new, setIsNew] = useState(true);

  async function handleRegister(e) {
    e.preventDefault();

    if (name.length === 0 || cnpj.length === 0) {
      toast.current.show({
        severity: "warn",
        summary: "Aviso",
        detail: "Preencha nome cnpj",
        life: 5000
      });

      setActivateDialog(false);
      return;
    }

    if (email && !emailIsValid(email)) {
      toast.current.show({
        severity: "warn",
        summary: "Aviso",
        detail: "Coloque um e-mail correto",
        life: 5000
      });

      setActivateDialog(false);
      return;
    }

    const data = {
      name: name,
      trading_name: nomeFantasia,
      cnpj: cnpj,
      contact_name: nomeContato,
      phone: telefone,
      email: email,
      address: {
        city: cidade,
        country: pais,
        postcode: cep,
        state: estado,
        street_name: rua,
        district: bairro,
        number: numero
      },
      address_link: addressLink
    };

    let response = __is_new ? "criado" : "alterada";

    try {
      unsetData();
      setSingleCompany({});
      setProductDialog(false);
      setLoading(true);
      if (__is_new) {
        await api.post("companies", data);
      } else {
        await api.put("companies/" + singleCompany.id, data);
      }

      await getCompanies();
      toast.current.show({
        severity: "success",
        summary: "Sucesso",
        detail: "Empresa " + response + " com sucesso",
        life: 5000
      });
    } catch (err) {
      if (err?.response?.status === 422) {
        toast.current.show({
          severity: "error",
          summary: "",
          detail: `CNPJ já existente no sistema`,
          life: 7000
        });
        setLoading(false);
        return;
      }
      toast.current.show({
        severity: "error",
        summary: "",
        detail: `Ocorreu um erro ao criar ou alterar empresa`,
        life: 7000
      });
    }

    document.body.style.position = null;
    setLoading(false);
  }

  const activateCompany = async () => {
    setActivateDialog(false);
    await changeCompanyStatus("activate");
  };

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [activateDialog, setActivateDialog] = useState(false);

  const deleteDialogFooter = (
    <div className={"d-flex justify-content-around align-items-center"}>
      <Button
        label="Não"
        className="p-button-text text-white bg-danger w-50"
        style={{ height: 40, fontWeight: "bold" }}
        onClick={() => setDeleteDialog(false)}
      />
      <Button
        label="Sim"
        className="p-button-text text-white bg-success w-50"
        style={{ height: 40, fontWeight: "bold" }}
        onClick={deactivateCompany}
      />
    </div>
  );

  const activateDialogFooter = (
    <div className={"d-flex justify-content-around align-items-center"}>
      <Button
        label="Não"
        className="p-button-text text-white bg-danger w-50"
        style={{ height: 40, fontWeight: "bold" }}
        onClick={() => setActivateDialog(false)}
      />
      <Button
        label="Sim"
        className="p-button-text text-white bg-success w-50"
        style={{ height: 40, fontWeight: "bold" }}
        onClick={activateCompany}
      />
    </div>
  );

  const handleEdit = (rowData) => {
    setLoading(true);
    setCallCepApi(false);
    getSingleCompany(rowData.id).then(() => {
      openNew(rowData);
    });
  };

  const [emailsModal, setEmailsModal] = useState(false);

  const openEmailsModal = (rowData) => {
    setSelectedItem(rowData);
    setEmailsModal(true);
  };

  const hideEmailsModal = () => {
    setEmailsModal(false);
    setSelectedItem({});
  };

  const actionBodyTemplate = (rowData) => {
    if (rowData.is_active) {
      return (
        <React.Fragment>
          <Button
            icon="pi pi-pencil"
            tooltip="Editar"
            tooltipOptions={{ position: "top" }}
            className="p-button-rounded p-button-warning text-white p-mr-2 me-2"
            onClick={() => handleEdit(rowData)}
          />
          <Button
            icon="pi pi-envelope"
            tooltip="E-mails"
            tooltipOptions={{ position: "top" }}
            className="p-button-rounded p-button-info text-white p-mr-2 me-2"
            onClick={() => openEmailsModal(rowData)}
          />
          <Button
            icon="pi pi-trash"
            tooltip="Inativar"
            tooltipOptions={{ position: "top" }}
            style={{ backgroundColor: "#9E3131", outline: 0, border: "none" }}
            className="p-button-rounded p-button text-white"
            onClick={() => {
              setDeleteDialog(true);
              setSingleCompany(rowData);
            }}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Button
            icon="pi pi-pencil"
            tooltip="Editar"
            tooltipOptions={{ position: "top" }}
            className="p-button-rounded p-button-warning text-white p-mr-2 me-2"
            onClick={() => handleEdit(rowData)}
          />
          <Button
            icon="pi pi-angle-double-up"
            tooltip="Ativar"
            tooltipOptions={{ position: "top" }}
            style={{ backgroundColor: "#003B17", outline: 0, border: "none" }}
            className="p-button-rounded p-button text-white"
            onClick={() => {
              setActivateDialog(true);
              setSingleCompany(rowData);
            }}
          />
        </React.Fragment>
      );
    }
  };

  const cnpjMask = (value) => {
    return value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  };

  const items = [
    {
      label: "Ordenar",
      items: [
        {
          label: "Nome",
          command: () => {
            setOrdenado("name");
          }
        },
        {
          label: "CNPJ",
          command: () => {
            setOrdenado("cnpj");
          }
        }
      ]
    },
    {
      label: "Filtrar",
      items: [
        {
          label: "Empresas " + (is_active ? "Inativas" : "Ativas"),
          command: () => {
            setIsActive(is_active === 1 ? 0 : 1);
            setPage(1);
          }
        }
      ]
    }
  ];

  const statusBodyTemplate = (rowData) => {
    if (rowData.is_active) {
      return (
        <Badge
          style={{ minWidth: 100, backgroundColor: "#003B17", color: "white" }}
          value={"Ativa"}
        />
      );
    }

    return (
      <Badge
        style={{ minWidth: 100, backgroundColor: "#9E3131", color: "white" }}
        value={"Inativa"}
      />
    );
  };

  const menu = useRef(null);

  const header = (
    <div className="table-header d-inline d-flex flex-row align-content-center justify-content-between">
      <div>
        <h5 className="p-m-0">Todos as empresas</h5>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => {
              setSearch(e.target.value);
              setPage(1);
            }}
            placeholder="Busca..."
          />
        </span>
      </div>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Salvar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleRegister}
      />
    </React.Fragment>
  );

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const getCep = async (cep) => {
    if (cep) {
      const rightCep = cep.replace(/\D/g, "");

      if (rightCep && rightCep.length > 7) {
        const url = "https://viacep.com.br/ws/" + cep + "/json/";
        const r = await axios({ method: "get", url: url });
        const address = r.data;
        setPais("Brasil");
        setCidade(address?.localidade);
        setBairro(address?.bairro);
        setEstado(address?.uf);
        setRua(address?.logradouro);
      }
    }
  };

  useEffect(() => {
    if (callCepApi) {
      getCep(cep).then();
    }
  }, [cep]);

  const changeCep = (value) => {
    setCep(value);
    setCallCepApi(true);
  };

  return (
    <>
      <div className="datatable-responsive-demo">
        <Toast ref={toast} position="bottom-right" />

        <div className="card">
          <Toolbar
            className="p-mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />

          <DataTable
            ref={dt}
            value={companies}
            dataKey="id"
            rows={rows}
            header={header}
            sortOrder={sortOrder}
            sortField={sortField}
            onSort={({ sortField, sortOrder }) => ordenar(sortField, sortOrder)}
            className={"p-datatable-responsive-demo"}
            loading={loading}
          >
            <Column headerStyle={{ width: "3rem" }} />
            <Column field="name" header="Nome" sortable />
            <Column field="trading_name" header="Nome fantasia" sortable />
            <Column
              field="cnpj"
              header="CPF/CNPJ"
              sortable
              body={(rowData) => cnpjMask(rowData.cnpj)}
            />
            <Column header="Status" body={statusBodyTemplate} sortable />
            <Column header="Ações" body={actionBodyTemplate} />
          </DataTable>
          <div
            className={"d-flex justify-content-center align-items-center p-3"}
          >
            <div>
              <Pagination
                className="pagination"
                color={"primary"}
                boundaryCount={1}
                count={Math.ceil(counter / rows)}
                onChange={handleChangePage}
              />
            </div>
            <RowsDropdown rows={rows} setRows={setRows} />
          </div>
        </div>

        <CreateEmpresaForm
          name={name}
          setName={setName}
          nomeFantasia={nomeFantasia}
          setNomeFantasia={setNomeFantasia}
          telefone={telefone}
          setTelefone={setTelefone}
          email={email}
          setEmail={setEmail}
          cnpj={cnpj}
          setCNPJ={setCNPJ}
          address={address}
          setAddress={setAddress}
          nomeContato={nomeContato}
          setNomeContato={setNomeContato}
          cep={cep}
          setCep={changeCep}
          pais={pais}
          setPais={setPais}
          cidade={cidade}
          setCidade={setCidade}
          estado={estado}
          setEstado={setEstado}
          rua={rua}
          setRua={setRua}
          bairro={bairro}
          setBairro={setBairro}
          numero={numero}
          setNumero={setNumero}
          header={"Dados da empresa"}
          visible={productDialog}
          footer={productDialogFooter}
          onHide={hideDialog}
          error={formError}
          setCallCepApi={setCallCepApi}
          emails={emails}
          setEmails={emails}
          addressLink={addressLink}
          setAddressLink={setAddressLink}
        />

        <CreateCompanyEmailsForm
          visible={emailsModal}
          selectedItem={selectedItem}
          onHide={hideEmailsModal}
          reload={getCompanies}
          toast={toast}
          setLoading={setLoading}
        />

        <Dialog
          visible={deleteDialog}
          style={{ width: "450px" }}
          modal
          footer={deleteDialogFooter}
          onHide={() => {
            setDeleteDialog(false);
            document.body.style.position = null;
          }}
        >
          <div className={"d-flex flex-column align-items-center"}>
            <img
              width={"150"}
              className={"pb-3"}
              src={rejectImage}
              alt={"reject image"}
            />
            <h4 className={"text-dark"}>Deseja inativar essa empresa?</h4>

            <p style={{ fontSize: 15 }}>
              Uma empresa inativada existe registrada mas você não pode criar
              chamados técnicos com ela
            </p>
          </div>
        </Dialog>

        <Dialog
          visible={activateDialog}
          style={{ width: "450px" }}
          modal
          footer={activateDialogFooter}
          onHide={() => {
            setActivateDialog(false);
            document.body.style.position = null;
          }}
        >
          <div className={"d-flex flex-column align-items-center"}>
            <img
              width={"150"}
              className={"pb-3"}
              src={verifyImage}
              alt={"reject image"}
            />
            <h4 className={"text-dark"}>Deseja ativar essa empresa?</h4>

            <p style={{ fontSize: 15 }}>
              Uma empresa inativada existe registrada mas você não pode criar
              chamados técnicos com ela
            </p>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default Table;
