import { formatToBrazilianCurrency } from "../../utils/masks";

export function totalPriceOrcamento(orcamento) {
  if (orcamento.parts) {
    const countParts = orcamento.parts.length;
    let totalPrice = 0;
    for (let i = 0; i < countParts; i++) {
      const quantity = orcamento.parts[i].quantity;
      const value = orcamento.parts[i].price;
      const thisValue = quantity * value;
      totalPrice = totalPrice + thisValue;
    }
    return totalPrice;
  }
}
export function formatDate(dataString) {
  let dataParts = dataString.split("T");
  let dataFormated = dataParts[0].split("-");
  return `${dataFormated[2]}/${dataFormated[1]}/${dataFormated[0]}`;
}

export const tableData = [
  {
    name: "id",
    header: "Número",
    sortable: true,
    body: (orcamento) => {
      const createdAt = new Date(orcamento.created_at);
      const year = createdAt.getFullYear();
      return `${year}.${orcamento?.id?.toString().padStart(4, "0")}`;
    }
  },
  {
    name: "company.name",
    header: "Empresa",
    headerStyle: { width: "20rem" },
    sortable: true
  },
  {
    name: "equipment.equipment",
    header: "Máquina",
    headerStyle: { width: "20rem" },
    sortable: true
  },
  {
    name: "price",
    header: "Valor",
    body: (orcamento) =>
      formatToBrazilianCurrency(totalPriceOrcamento(orcamento)),
    sortable: true
  },
  {
    name: "created_at",
    header: "Data criação",
    body: (orcamento) => {
      const data = formatDate(orcamento.created_at);
      return data;
    }
  },
  {
    header: "Ações",
    body: null
  }
];
