export const phone = (phone) => {
  if (!phone || phone.length > 15) {
    return;
  }

  let v = phone.replace(/\D/g, "");
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
  v = v.replace(/(\d)(\d{4})$/, "$1-$2");
  return v;
};

export const renderServiceNumber = (rowData) => {
  const date = rowData.workshop_received_date || rowData.created_at;
  const year = date?.split("-")[0];
  return year + "." + rowData.service_number;
};

export const dateToHour = (date) => {
  if (!date) {
    return date;
  }
  return `${new Date(date).getHours().toString().padStart(2, "0")}:${new Date(
    date
  )
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
};

export function formatToBrazilianCurrency(value) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value);
}

export function maskCNPJ(cnpj) {
  if (!cnpj) {
    return cnpj;
  }
  return cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5"
  );
}

export function secondsToHoursMinutes(seconds) {
  if (!seconds) return "00:00";
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
}

const masks = {
  phone,
  renderServiceNumber,
  dateToHour,
  formatToBrazilianCurrency,
  maskCNPJ,
  secondsToHoursMinutes
};

export default masks;
