import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Fade } from "@material-ui/core";
import { Toast } from "primereact/toast";
import Input from "../Form/Input";
import { Calendar } from "primereact/calendar";
import { useForm, Controller } from "react-hook-form";
import api from "../../services/api";
import { Dropdown } from "primereact/dropdown";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth } from "../../contexts/AuthContext";

const schema = z.object({
  error_description: z.string().nonempty({ message: "Digite a descrição." }),
  hour_meter: z.string().nonempty({ message: "Digite o horimetro." }),
  mileage: z.any(),
  cause: z.string().nonempty({ message: "Digite a causa." }),
  solution: z.string().nonempty({ message: "Digite a solução do problema." }),
  has_pendency: z
    .boolean()
    .refine((value) => value === true || value === false, {
      message: "Selecione se houve pendência ou não."
    }),
  pendency: z.string().nullable(),
  testeEfetuado: z
    .boolean()
    .refine((value) => value === true || value === false, {
      message: "Selecione se o teste foi efetuado ou não."
    }),
  misuse: z.boolean().refine((value) => value === true || value === false, {
    message: "Selecione se teve mau uso ou não."
  }),
  resultadoPositivo: z
    .boolean()
    .refine((value) => value === true || value === false, {
      message: "Selecione se o resultado foi positivo ou não."
    }),
  aplicacaoDePecas: z
    .boolean()
    .refine((value) => value === true || value === false, {
      message: "Selecione se o resultado foi positivo ou não."
    }),
  costumer_name: z.string().nullable(),
  costumer_email: z.string().email().optional().or(z.literal("")),
  date: z.date({
    required_error: "Digite a data.",
    invalid_type_error: "Digite a data."
  }),
  start_attendance: z.date({
    required_error: "Digite o horário de início.",
    invalid_type_error: "Digite o horário de início."
  }),
  end_attendance: z.date({
    required_error: "Digite o horário final.",
    invalid_type_error: "Digite o horário final."
  }),
  service_call: z.object({
    id: z.any()
  }),
  service_call_id: z.any(),
  is_active: z.any(),
  is_guarantee: z.any(),
  attendance: z.string(),
  displacement_time: z.string(),
  attendance_time: z.string(),
  created_by: z.number().min(1, "Selecione um técnico.")
});

export default function CreateCorrectiveForm({
  visible = true,
  onHide = () => {},
  currentCorrective,
  toast,
  setLoading,
  getCorretivas
}) {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch
  } = useForm({
    resolver: zodResolver(schema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    shouldFocusError: false
  });

  const [empresas, setEmpresas] = useState([{ value: "", label: "" }]);
  const [techs, setTechs] = useState();
  const [firstTechnician, setFirstTechnician] = useState();
  const [secondTechnician, setSecondTechnician] = useState();
  const [serviceCalls, setServiceCalls] = useState([{ value: "", label: "" }]);
  const [companyByServiceCall, setCompanyByServiceCall] = useState("");
  const [equipmentByServiceCall, setEquipmentByServiceCall] = useState("");
  const [serviceCallState, setServiceCallState] = useState();

  const watchEmpresa = watch("company_id");
  const watchDate = watch("date");
  const watchCreated_by = watch("created_by");
  const watchHasPendency = watch("has_pendency");
  const { access_type } = useAuth();

  useEffect(() => {
    if (access_type === "Admin") {
      api.get("service_calls/allServiceCalls").then((response) => {
        setServiceCalls(response.data);
      });
      api.get("/users/getTechs").then((response) => {
        setTechs(response.data);
      });
    }
  }, []);

  const formatDate = (dataString) => {
    const date = new Date(`${dataString} 10:00`);
    return date;
  };

  useEffect(() => {
    if (currentCorrective) {
      setValue("date", formatDate(currentCorrective?.date));
      setValue("error_description", currentCorrective?.error_description);
      setValue("hour_meter", currentCorrective?.hour_meter);
      const mileage = parseInt(currentCorrective?.mileage);
      setValue("mileage", isNaN(mileage) ? 0 : currentCorrective?.mileage);
      setValue("cause", currentCorrective?.cause);
      setValue("solution", currentCorrective?.solution);
      setValue("has_pendency", Boolean(currentCorrective?.has_pendency));
      setValue("pendency", currentCorrective?.pendency);
      setValue("testeEfetuado", Boolean(currentCorrective?.testeEfetuado));
      setValue("misuse", Boolean(currentCorrective?.misuse));
      setValue(
        "resultadoPositivo",
        Boolean(currentCorrective?.resultadoPositivo)
      );
      setValue(
        "aplicacaoDePecas",
        Boolean(currentCorrective?.aplicacaoDePecas)
      );
      setValue(
        "costumer_name",
        currentCorrective?.costumer_name ||
          currentCorrective?.company?.contact_name ||
          ""
      );
      setValue("costumer_email", currentCorrective?.costumer_email || "");
      if (
        currentCorrective?.attendance &&
        currentCorrective?.attendance !== "00:00"
      ) {
        setValue(
          "start_attendance",
          new Date(
            `1970-01-01T${currentCorrective?.attendance
              .split("-")[0]
              .trim()}:00`
          )
        );
        setValue(
          "end_attendance",
          new Date(
            `1970-01-01T${currentCorrective?.attendance
              .split("-")[1]
              .trim()}:00`
          )
        );
      } else {
        setValue("start_attendance", new Date(`1970-01-01T00:00:00`));
        setValue("end_attendance", new Date(`1970-01-01T00:00:00`));
      }
      setValue("company", currentCorrective?.company);
      setValue("service_call", currentCorrective?.service_call);
      setValue("service_call_id", currentCorrective?.service_call?.id);
      setValue("attendance", "");
      setValue("attendance_time", "");
      setValue("displacement_time", "");
      setValue("created_by", currentCorrective?.created_by?.id);
      setValue("is_active", currentCorrective?.is_active);
      setValue("is_guarantee", currentCorrective?.is_guarantee);
      setServiceCallState(currentCorrective?.service_call);
      setCompanyByServiceCall(currentCorrective?.company);
      setEquipmentByServiceCall(currentCorrective?.service_call?.equipment);
      setFirstTechnician(currentCorrective?.creator?.name || "");
    } else {
      setValue("error_description", "");
      setValue("company_id", "");
      setValue("equipment_id", "");
      setValue("hour_meter", "");
      setValue("mileage", "");
      setValue("cause", "");
      setValue("solution", "");
      setValue("has_pendency", "");
      setValue("pendency", "");
      setValue("testeEfetuado", "");
      setValue("misuse", "");
      setValue("resultadoPositivo", "");
      setValue("aplicacaoDePecas", "");
      setValue("costumer_name", "");
      setValue("costumer_email", "");
      setValue("start_attendance", "");
      setValue("end_attendance", "");
      setValue("attendance", "");
      setValue("attendance_time", "");
      setValue("displacement_time", "");
      setValue("company", "");
      setValue("service_call", { id: "" });
      setValue("service_call_id", "");
      setValue("date", formatDate(getTodayDate()));
      setValue("created_by", "");
      setValue("is_active", "");
      setValue("is_guarantee", "");
      setServiceCallState(null);
      setCompanyByServiceCall("");
      setEquipmentByServiceCall("");
    }
  }, [currentCorrective]);

  const submitForm = async (data) => {
    setLoading(true);
    hideForm();
    try {
      if (isNaN(data.mileage)) {
        data.mileage = 0;
      } else {
        data.mileage = parseInt(data.mileage);
      }
      data.date = data.date.toISOString().split("T")[0];
      data.hour_meter = parseInt(data.hour_meter);
      data.attendance = getAttendance(
        data.start_attendance,
        data.end_attendance
      );
      data.attendance_time = getDifferenceAttendanceTime(data.attendance);
      data.company_id = companyByServiceCall?.id;

      data.second_tech = secondTechnician?.id;
      data.pendency = data.pendency ?? currentCorrective?.pendency;

      if (currentCorrective) {
        data.equipment_id = currentCorrective?.service_call?.equipment_id;
        data.service_call_id = data?.service_call?.id;
        data.created_by = currentCorrective?.created_by?.id;
        await api.put(
          `/corrective/update-corrective/${currentCorrective.id}`,
          data
        );
      } else {
        data.created_by = firstTechnician?.id;
        data.service_call_id = serviceCallState?.id;
        data.service_call = serviceCallState;
        data.is_active = companyByServiceCall?.is_active;
        data.is_guarantee = companyByServiceCall?.is_guarantee;
        data.equipment_id = serviceCallState?.equipment_id;
        await api.post(`/corrective/create-corrective-web`, data);
        setServiceCallState("");
        setCompanyByServiceCall("");
        setEquipmentByServiceCall("");
        setFirstTechnician("");
        setSecondTechnician("");
      }
      toast.current.show({
        severity: "success",
        summary: "",
        detail: `Corretiva enviada com sucesso!`,
        life: 3000
      });
      setLoading(false);
      getCorretivas();
    } catch (error) {
      toast.current.show({
        severity: "err",
        summary: "",
        detail: `Erro ao enviar corretiva!`,
        life: 3000
      });
      setLoading(false);
    }
  };
  const getDifferenceAttendanceTime = (attendance) => {
    const [start_time, end_time] = attendance.split("-");
    const [start_hours, start_minutes] = start_time.split(":").map(Number);
    const [end_hours, end_minutes] = end_time.split(":").map(Number);

    let differenceInMinutes =
      end_hours * 60 + end_minutes - (start_hours * 60 + start_minutes);

    if (differenceInMinutes < 0) {
      differenceInMinutes += 24 * 60;
    }

    const differenceHours = Math.floor(differenceInMinutes / 60);
    const differenceMinutes = differenceInMinutes % 60;

    const formattedDifference = `${String(differenceHours).padStart(
      2,
      "0"
    )}:${String(differenceMinutes).padStart(2, "0")}`;

    return formattedDifference;
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  };

  const getAttendance = (start_time, end_time) => {
    const startTime = new Date(start_time);
    const endTime = new Date(end_time);

    let startH = startTime.getUTCHours() - 3;
    let endH = endTime.getUTCHours() - 3;
    const startMin = startTime.getUTCMinutes().toString()?.padStart(2, "0");
    const endMin = endTime.getUTCMinutes().toString()?.padStart(2, "0");
    if (startH < 0) {
      startH += 24;
    }
    if (endH < 0) {
      endH += 24;
    }
    const formattedStartH = startH.toString()?.padStart(2, "0");
    const formattedEndH = endH.toString()?.padStart(2, "0");
    return `${formattedStartH}:${startMin}-${formattedEndH}:${endMin}`;
  };

  const handleChangeSelect = (selectedOption) => {
    const company = empresas.find(
      (empresa) => empresa.id === selectedOption.value
    );
    if (company) {
      return company;
    }
  };
  const handleChangeSelectServiceCall = (selectedOption) => {
    const serviceCall = serviceCalls.find(
      (service) => service.id === selectedOption.value
    );
    if (serviceCall) {
      const updatedServiceCallState = serviceCall;
      setValue("costumer_name", updatedServiceCallState?.company?.contact_name);
      setValue("costumer_email", updatedServiceCallState?.company?.email);
      setServiceCallState(updatedServiceCallState);
      setCompanyByServiceCall(updatedServiceCallState?.company);
      setEquipmentByServiceCall(updatedServiceCallState?.equipment);
      return serviceCall;
    }
  };

  const handleChangeSelectTechnician = (selectedOption) => {
    const technician = techs.find((tech) => tech.id === selectedOption.value);
    if (technician) {
      const selectedTechnician = technician;
      setFirstTechnician(selectedTechnician);
      const filteredTechs = techs.filter(
        (tech) => tech.name !== selectedTechnician.name
      );
      setTechs(filteredTechs);
      return technician;
    }
  };
  const handleChangeSelectSecondTechnician = (selectedOption) => {
    const technician = techs.find((tech) => tech.id === selectedOption.value);
    if (technician) {
      if (technician === firstTechnician) {
        return;
      }
      const selectedTechnician = technician;
      setSecondTechnician(selectedTechnician);
      return technician;
    }
  };

  const handleChangeSelectEquipment = (selectedOption) => {
    const equipment = watchEmpresa?.equipments.find(
      (equipment) => equipment.id == selectedOption.value
    );
    if (equipment) {
      return equipment;
    }
  };

  const hideForm = () => {
    setServiceCallState("");
    setCompanyByServiceCall("");
    setEquipmentByServiceCall("");
    setFirstTechnician("");
    setSecondTechnician("");
    reset();
    onHide();
  };

  const footer = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          hideForm();
        }}
      />
      <Button
        label={currentCorrective ? "Editar" : "Criar"}
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleSubmit((data) => submitForm(data))}
      />
    </React.Fragment>
  );
  const renderServiceNumber = (rowData) => {
    const year = rowData?.service_date?.split("-")[0];
    return year + "." + rowData?.service_number;
  };
  const renderDisplayServiceCall = (serviceCall) => {
    const year = serviceCall?.service_date?.split("-")[0];
    return year + "." + serviceCall?.service_number;
  };

  const createForm = () => {
    return (
      <div className="container pt-2">
        <Fade in={true}>
          <div className="row">
            <form
              className={
                "d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center"
              }
            >
              <div className={"row pb-1"}>
                <div className={"col-md-12 pb-2 d-flex flex-column"}>
                  <label htmlFor="Service call">Chamado: </label>

                  <Controller
                    control={control}
                    name="service_call_id"
                    render={({ field: { onChange, ...field } }) => (
                      <Dropdown
                        disabled={Boolean(currentCorrective)}
                        placeholder={
                          currentCorrective
                            ? renderDisplayServiceCall(
                                currentCorrective?.service_call
                              )
                            : "Selecione o número do chamado"
                        }
                        options={serviceCalls.map((serviceCall) => ({
                          ...serviceCall,
                          renderNumber: renderServiceNumber(serviceCall)
                        }))}
                        valueTemplate={
                          serviceCallState
                            ? renderServiceNumber(serviceCallState)
                            : ""
                        }
                        labelTemplate={
                          renderServiceNumber(serviceCallState) || ""
                        }
                        optionLabel="renderNumber"
                        optionValue="id"
                        onChange={(event) => {
                          const serviceCall =
                            handleChangeSelectServiceCall(event);
                          onChange(serviceCall);
                        }}
                        label={""}
                        filter
                        error={errors.company?.message}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>

              <div className={"row pb-1"}>
                <div className={"col-md-12 pb-2 d-flex flex-column"}>
                  <label htmlFor="Empresa">Empresa: </label>
                  <Controller
                    control={control}
                    name="company_id"
                    render={({ field: { onChange, ...field } }) => (
                      <Dropdown
                        value={companyByServiceCall}
                        optionLabel={"name"}
                        optionValue={"id"}
                        disabled
                        placeholder={companyByServiceCall?.name || ""}
                        label={"Empresa"}
                        filter
                        error={errors.company?.message}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={"row pb-1"}>
                <div className={"col-md-12 pb-2 d-flex flex-column"}>
                  <label htmlFor="Equipamento">Equipamento: </label>
                  <Controller
                    control={control}
                    name="equipment_id"
                    render={({ field: { onChange, ...field } }) => (
                      <Dropdown
                        value={equipmentByServiceCall}
                        disabled
                        placeholder={equipmentByServiceCall?.equipment || ""}
                        onChange={(event) => {
                          const equipment = handleChangeSelectEquipment(event);
                          onChange(equipment);
                        }}
                        optionLabel={"equipment"}
                        optionValue={"id"}
                        filter
                        label={"Equipamento"}
                      />
                    )}
                  />
                </div>
              </div>

              <div className={"d-flex justify-content-around"}>
                <div className={"col-md-5 pb-2 d-flex flex-column"}>
                  <label htmlFor="Técnico">Técnico: </label>
                  <Controller
                    control={control}
                    name="created_by"
                    render={({ field: { onChange, ...field } }) => {
                      return (
                        <Dropdown
                          value={
                            currentCorrective
                              ? currentCorrective?.created_by?.id
                              : firstTechnician?.id
                          }
                          disabled={Boolean(currentCorrective)}
                          placeholder={
                            firstTechnician ? firstTechnician?.name : "Técnico"
                          }
                          className={
                            errors.created_by ? "mb-2 border-danger" : "mb-2"
                          }
                          onChange={(event) => {
                            const technician =
                              handleChangeSelectTechnician(event);
                            onChange(technician?.id);
                          }}
                          options={techs}
                          optionLabel={"name"}
                          optionValue={"id"}
                          filter
                          label={"technician"}
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
                <div className={"col-md-5 pb-2 d-flex flex-column"}>
                  <label htmlFor="Técnico">Técnico Secundario* : </label>
                  <Controller
                    control={control}
                    name="second_tech"
                    render={({ field: { onChange, ...field } }) => (
                      <Dropdown
                        value={field.value}
                        disabled={firstTechnician ? false : true}
                        placeholder={
                          secondTechnician
                            ? secondTechnician?.name
                            : "Técnico Secundário"
                        }
                        onChange={(event) => {
                          handleChangeSelectSecondTechnician(event);
                        }}
                        options={techs}
                        optionLabel={"name"}
                        optionValue={"id"}
                        filter
                        label={"technician"}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>

              <div className={"row pb-2"}>
                <div className={"col-md-12 d-flex flex-column"}>
                  <Input
                    error={errors.error_description?.message}
                    name={"error_description"}
                    label={"Descrição"}
                    register={register}
                  />
                </div>
              </div>
              <div className={"d-flex justify-content-around "}>
                <div className="col-md-4 pb-3 d-flex flex-column">
                  <div>
                    <Input
                      name={"mileage"}
                      error={errors.mileage?.message}
                      label={"Quilometragem"}
                      register={register}
                      type="number"
                    />
                  </div>
                </div>

                <div className={"col-md-4 pb-3 d-flex flex-column"}>
                  <div>
                    <Input
                      error={errors.hour_meter?.message}
                      name={"hour_meter"}
                      label={"Horimetro"}
                      register={register}
                      type="number"
                    />
                  </div>
                </div>
              </div>

              <div className={"d-flex justify-content-around "}>
                <div className="col-md-3 d-flex flex-column">
                  <label htmlFor="startTime">Data</label>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field: { onChange, ...field } }) => {
                      return (
                        <Calendar
                          className={
                            errors.date?.message ? "p-invalid mb-2" : "mb-2"
                          }
                          value={field.value}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                  {errors.date?.message && (
                    <h6 className="text-danger font-weight-bold">
                      {errors.date?.message}
                    </h6>
                  )}
                </div>
                <div className="col-md-3 d-flex flex-column">
                  <label htmlFor="startTime">Hora de início</label>
                  <Controller
                    control={control}
                    name="start_attendance"
                    render={({ field: { onChange, ...field } }) => {
                      return (
                        <Calendar
                          className={
                            errors.start_attendance?.message
                              ? "p-invalid mb-2"
                              : "mb-2"
                          }
                          value={field.value}
                          onChange={onChange}
                          timeOnly
                        />
                      );
                    }}
                  />
                  {errors.start_attendance?.message && (
                    <h6 className="text-danger font-weight-bold">
                      {errors.start_attendance?.message}
                    </h6>
                  )}
                </div>
                <div className="col-md-3 d-flex flex-column">
                  <label htmlFor="startTime">Hora final</label>
                  <Controller
                    control={control}
                    name="end_attendance"
                    render={({ field: { onChange, ...field } }) => {
                      return (
                        <Calendar
                          className={
                            errors.end_attendance?.message
                              ? "p-invalid mb-2"
                              : "mb-2"
                          }
                          value={field?.value}
                          onChange={onChange}
                          timeOnly
                        />
                      );
                    }}
                  />
                  {errors.end_attendance?.message && (
                    <h6 className="text-danger font-weight-bold">
                      {errors.end_attendance?.message}
                    </h6>
                  )}
                </div>
              </div>

              <div className={"row pb-1"}>
                <div className={"col-md-12 d-flex flex-column"}>
                  <Input
                    name={"cause"}
                    error={errors.cause?.message}
                    label={"Causa"}
                    register={register}
                  />
                </div>
              </div>

              <div className={"row pb-1"}>
                <div className={"col-md-12 d-flex flex-column"}>
                  <Input
                    error={errors.solution?.message}
                    name={"solution"}
                    label={"Solução"}
                    register={register}
                  />
                </div>
              </div>

              <div className={"d-flex justify-content-around"}>
                <div className={"col-md-2 pb-3 d-flex flex-column"}>
                  <label htmlFor="Houve pendencia?">Houve pendência? </label>
                  <Controller
                    control={control}
                    name="has_pendency"
                    render={({ field: { onChange, ...field } }) => (
                      <Dropdown
                        className={
                          errors.has_pendency?.message
                            ? "mb-2 border-danger"
                            : "mb-2"
                        }
                        valueTemplate={field?.value ? "Sim" : "Não"}
                        optionLabel="label"
                        optionValue="value"
                        options={[
                          { label: "Sim", value: true },
                          { label: "Não", value: false }
                        ]}
                        value={field.value}
                        onChange={onChange}
                        {...field}
                      />
                    )}
                  />
                </div>
                {watchHasPendency && (
                  <div className={"col-md-6 pb-3 d-flex flex-column"}>
                    <Input
                      name={"pendency"}
                      label={"Pendência"}
                      register={register}
                    />
                  </div>
                )}
              </div>

              <div className={"d-flex justify-content-around"}>
                <div className={"col-md-2 pb-3 d-flex flex-column"}>
                  <label htmlFor="Teste efetuado?">Teste efetuado? </label>
                  <Controller
                    control={control}
                    name="testeEfetuado"
                    render={({ field: { onChange, ...field } }) => (
                      <Dropdown
                        className={
                          errors.testeEfetuado?.message
                            ? "mb-2 border-danger"
                            : "mb-2"
                        }
                        options={[
                          { label: "Sim", value: true },
                          { label: "Não", value: false }
                        ]}
                        value={field.value}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className={"col-md-2 pb-3 d-flex flex-column"}>
                  <label htmlFor="Mau uso?">Mau uso? </label>
                  <Controller
                    control={control}
                    name="misuse"
                    render={({ field: { onChange, ...field } }) => (
                      <Dropdown
                        className={
                          errors.misuse?.message ? "mb-2 border-danger" : "mb-2"
                        }
                        options={[
                          { label: "Sim", value: true },
                          { label: "Não", value: false }
                        ]}
                        value={field.value}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className={"col-md-2 pb-3 d-flex flex-column"}>
                  <label htmlFor="Resultado positivo?">
                    Resultado positivo?{" "}
                  </label>
                  <Controller
                    control={control}
                    name="resultadoPositivo"
                    render={({ field: { onChange, ...field } }) => (
                      <Dropdown
                        className={
                          errors.resultadoPositivo?.message
                            ? "mb-2 border-danger"
                            : "mb-2"
                        }
                        options={[
                          { label: "Sim", value: true },
                          { label: "Não", value: false }
                        ]}
                        value={field.value}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className={"col-md-2 pb-3 d-flex flex-column"}>
                  <label htmlFor="Aplicação de peças?">
                    Aplicação de peças?
                  </label>
                  <Controller
                    control={control}
                    name="aplicacaoDePecas"
                    render={({ field: { onChange, ...field } }) => (
                      <Dropdown
                        className={
                          errors.aplicacaoDePecas?.message
                            ? "mb-2 border-danger"
                            : "mb-2"
                        }
                        valueTemplate={field?.value ? "Sim" : "Não"}
                        optionLabel="label"
                        optionValue="value"
                        options={[
                          { label: "Sim", value: true },
                          { label: "Não", value: false }
                        ]}
                        value={field.value}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
              </div>

              {serviceCallState && (
                <div className={"d-flex justify-content-around"}>
                  <div className={"col-md-4 d-flex flex-column"}>
                    <Input
                      name={"costumer_name"}
                      placeholder={
                        serviceCallState?.company?.contact_name || "Nome"
                      }
                      label={"Nome do cliente"}
                      register={register}
                    />
                  </div>
                  <div className={"col-md-6 d-flex flex-column"}>
                    <Input
                      placeholder={serviceCallState?.company?.email || "E-mail"}
                      name={"costumer_email"}
                      type="email"
                      label={"E-mail do cliente"}
                      register={register}
                    />
                  </div>
                </div>
              )}
            </form>
          </div>
        </Fade>
      </div>
    );
  };

  return (
    <>
      <Dialog
        visible={visible}
        style={{ marginTop: 60, width: "80rem" }}
        className="p-fluid"
        modal
        onHide={hideForm}
        header={"Editar corretiva"}
        footer={footer}
      >
        <div className="container pt-2"> {createForm()} </div>
      </Dialog>
    </>
  );
}
