import { Collapse, Menu, MenuItem } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  AlarmOn,
  Build,
  Business,
  Description,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  InsertDriveFile,
  MoreVert,
  PeopleAlt,
  SettingsInputHdmi,
  Map,
  AttachMoney
} from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import clsx from "clsx";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: 20
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    background: "#000921"
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

export default function PersistentDrawerLeft({ children }) {
  const context = useAuth();
  const { logo, access_type, name } = useAuth();

  const [openList, setOpenList] = React.useState(false);
  const [openPreventivesList, setOpenPreventivesList] = React.useState(false);
  const history = useHistory();
  const handleClick = () => {
    setOpenList(!openList);
  };

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  async function handleLogout() {
    await context.Logout();
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [openEquipmentsList, setOpenEquipmentsList] = useState(false);

  const handleClose = (redirect = null) => {
    document.body.style.position = null;
    setAnchorEl(null);

    if (redirect) {
      history.push(redirect);
    }

    handleDrawerClose();
    setOpenPreventivesList(false);
    setOpenList(false);
    setOpenEquipmentsList(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={
          clsx(classes.appBar, {
            [classes.appBarShift]: open
          }) + " d-flex flex-row align-items-center justify-content-between"
        }
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            <img
              width={"100px"}
              src={process.env.REACT_APP_LARAVEL_APP + logo}
              alt={"logo"}
            />
          </Typography>
        </Toolbar>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <strong>{name}</strong>
          <MoreVert
            style={{ fontSize: 40, cursor: "pointer" }}
            onClick={handleOpen}
            className={"pe-3"}
          />
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleClose("/profile")}> Perfil</MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ExitToApp className={"pe-2"} /> Logout
          </MenuItem>
        </Menu>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button onClick={() => handleClose("/")}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={"Página inicial"} />
          </ListItem>

          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <Description />
            </ListItemIcon>
            <ListItemText primary="Chamados" />
            {openList ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleClose("/chamados")}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary="Ativos" />
              </ListItem>

              {access_type === "Admin" && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => handleClose("/chamados/pendentes")}
                >
                  <ListItemIcon>
                    <AlarmOn />
                  </ListItemIcon>
                  <ListItemText primary="Pendentes" />
                </ListItem>
              )}
            </List>
          </Collapse>
          <ListItem button onClick={() => handleClose("/ordens-de-servico")}>
            <ListItemIcon>
              <InsertDriveFile />
            </ListItemIcon>
            <ListItemText primary={"Ordem de serviço"} />
          </ListItem>
          <ListItem
            button
            onClick={() => setOpenPreventivesList(!openPreventivesList)}
          >
            <ListItemIcon>
              <Build />
            </ListItemIcon>
            <ListItemText primary="Relatorios" />
            {openList ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openPreventivesList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleClose("/preventivas/")}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={"Preventivas"} />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleClose("/corretivas")}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={"Corretivas"} />
              </ListItem>
              {access_type === "Admin" && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => handleClose("/relatorios")}
                >
                  <ListItemIcon>
                    <InsertDriveFile />
                  </ListItemIcon>
                  <ListItemText primary={"Todos"} />
                </ListItem>
              )}
            </List>
          </Collapse>

          <ListItem
            button
            onClick={() => setOpenEquipmentsList(!openEquipmentsList)}
          >
            <ListItemIcon>
              <SettingsInputHdmi />
            </ListItemIcon>
            <ListItemText primary="Equipamentos" />
            {openEquipmentsList ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={openEquipmentsList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleClose("/equipamentos")}
              >
                <ListItemIcon>
                  <SettingsInputHdmi />
                </ListItemIcon>
                <ListItemText primary="Ativos" />
              </ListItem>

              {access_type === "Admin" && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => handleClose("/equipamentos-pendentes")}
                >
                  <ListItemIcon>
                    <AlarmOn />
                  </ListItemIcon>
                  <ListItemText primary="Pendentes" />
                </ListItem>
              )}
            </List>
          </Collapse>

          {access_type === "Admin" && (
            <ListItem button onClick={() => handleClose("/empresas")}>
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              <ListItemText primary="Empresas" />
            </ListItem>
          )}

          {access_type === "Admin" && (
            <ListItem button onClick={() => handleClose("/mapa-empresas")}>
              <ListItemIcon>
                <Map />
              </ListItemIcon>
              <ListItemText primary="Mapa empresas" />
            </ListItem>
          )}

          {access_type === "Admin" && (
            <ListItem button onClick={() => handleClose("/users")}>
              <ListItemIcon>
                <PeopleAlt />
              </ListItemIcon>
              <ListItemText primary="Usuários" />
            </ListItem>
          )}
          {access_type === "Admin" && (
            <ListItem button onClick={() => handleClose("/pecas")}>
              <ListItemIcon>
                <Build />
              </ListItemIcon>
              <ListItemText primary={"Peças"} />
            </ListItem>
          )}
          {access_type === "Admin" && (
            <ListItem button onClick={() => handleClose("/orcamento")}>
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>
              <ListItemText primary="Orçamento de peças" />
            </ListItem>
          )}
          {access_type === "Admin" && (
            <ListItem button onClick={() => handleClose("/dashboard")}>
              <ListItemIcon>
                <InsertDriveFile />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItem>
          )}
        </List>
        <Divider />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
}
