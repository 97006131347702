import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { createContext, useContext, useRef, useState } from "react";
import api from "../../services/api";
import { tableData } from "./ConstData";

const OrcamentoContext = createContext({});

export default function OrcamentoProvider({ children }) {
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const toast = useRef(null);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const getFromApi = ({
    search = "",
    page = 1,
    orderBy = "",
    beginDate = "",
    endDate = "",
    order = -1,
    rows = 10,
    is_active = 1,
    setCounter = () => {}
  }) => {
    setLoading(true);
    api
      .get(
        `/orcamento?page=${page}&search=${search}&beginDate=${beginDate}&endDate=${endDate}`
      )
      .then((response) => {
        setItems(response.data.data);
        setCounter(response.data.total);
      })
      .finally(() => setLoading(false));
  };

  const createOrcamento = (payload) => {
    setLoading(true);
    api
      .post(`/orcamento/create`, payload)
      .then((response) => {
        if (!response.data || response?.data?.errors) {
          for (let i in response.errors) {
            toast.current.show({
              severity: "error",
              summary: "",
              detail: response.errors[i],
              life: 7000
            });
          }
          return;
        }
        toast.current.show({
          severity: "success",
          summary: "",
          detail: "Criado com sucesso.",
          life: 7000
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail: "Erro ao criar.",
          life: 7000
        });
      })
      .finally(() => {
        setLoading(false);
        getFromApi({});
      });
  };

  const updateOrcamento = (payload, orcamento_id) => {
    setLoading(true);
    api
      .put(`/orcamento/${orcamento_id}`, payload)
      .then((response) => {
        if (!response.data || response?.data?.errors) {
          for (let i in response.errors) {
            toast.current.show({
              severity: "error",
              summary: "",
              detail: response.errors[i],
              life: 7000
            });
          }
          return;
        }
        toast.current.show({
          severity: "success",
          summary: "",
          detail: "Alterado com sucesso.",
          life: 7000
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail: "Erro ao alterar.",
          life: 7000
        });
      })
      .finally(() => {
        setLoading(false);
        setSelectedItem({});
        getFromApi({});
      });
  };

  const deleteOrcamento = () => {
    closeDeleteDialog();
    setLoading(true);
    const id = selectedItem.id;
    setSelectedItem({});

    api
      .delete(`/orcamento/${id}`)
      .then(() => {
        return toast.current.show({
          severity: "success",
          summary: "",
          detail: `Inativado com sucesso!`,
          life: 7000
        });
      })
      .catch(() =>
        toast.current.show({
          severity: "error",
          summary: "",
          detail: `Ocorreu um erro ao inativar`,
          life: 7000
        })
      )
      .finally(() => {
        setLoading(false);
        getFromApi({});
        setSelectedItem({});
      });
  };

  const closeDeleteDialog = () => {
    setSelectedItem({});
    setDeleteDialog(false);
  };

  const contextData = {
    items,
    setItems,
    selectedItem,
    setSelectedItem,
    loading,
    setLoading,
    deleteDialog,
    setDeleteDialog,
    closeDeleteDialog,
    title: "Orçamento",
    tableData,
    getFromApi,
    createOrcamento,
    updateOrcamento,
    deleteOrcamento,
    hideDateFilter: false
  };

  return (
    <OrcamentoContext.Provider value={contextData}>
      <Toast ref={toast} position="bottom-right" />
      {children}
    </OrcamentoContext.Provider>
  );
}

export function useOrcamento() {
  return useContext(OrcamentoContext);
}
