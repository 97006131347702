import React from "react";

import Routes from "./routes/routes";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "primereact/resources/themes/saga-orange/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { locale, addLocale } from "primereact/api";

addLocale("pt-BR", {
  firstDayOfWeek: 1,
  dayNames: [
    "domingo",
    "segunda",
    "terça",
    "quarta",
    "quinta",
    "sexta",
    "sábado"
  ],
  dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
  dayNamesMin: ["Do", "Sg", "Te", "Qa", "Qi", "Se", "Sa"],
  monthNames: [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro"
  ],
  monthNamesShort: [
    "jan",
    "feb",
    "mar",
    "abr",
    "mai",
    "jun",
    "jul",
    "ago",
    "set",
    "out",
    "nov",
    "dez"
  ],
  today: "Hoje",
  clear: "Limpo",
  dateFormat: "dd/mm/yy"
});
const queryClient = new QueryClient();

locale("pt-BR");

import "./global.css";
import { AuthProvider } from "./contexts/AuthContext";
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
