export const handleGetReportNumber = (report) => {
  if (report?.auto_report) {
    return String(report?.auto_report)?.padStart(6, "0") ?? "Não preenchido";
  }
  return report?.report?.padStart(6, "0") ?? "Não preenchido";
};

export const transformDayToHour = (TimeInDays) => {
  let timeInHours = (TimeInDays * 24).toFixed(2);
  return timeInHours;
};
