import Pagination from "@material-ui/lab/Pagination";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { Menu } from "primereact/menu";
import { useAuth } from "../../../contexts/AuthContext";
import api from "../../../services/api";
import { emailIsValid } from "../../../utils/validations";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import masks from "../../../utils/masks";

const Table = () => {
  const { access_type } = useAuth();
  const [user_type, setUserType] = useState("");

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }, []);

  const [productDialog, setProductDialog] = useState(false);
  const [singleCompany, setSingleCompany] = useState({});

  const [loading, setLoading] = useState(false);
  const [formError, setError] = useState("");

  const toast = useRef(null);
  const dt = useRef(null);
  const [users, setUsers] = useState([]);
  const [counter, setCounter] = useState([]);
  const [search, setSearch] = useState("");
  const [is_active, setIsActive] = useState(1);
  const [page, setPage] = useState(1);
  const [ordenado, setOrdenado] = useState("");

  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("name");
  const ordenar = (sortField, sortOrder) => {
    setSortOrder(sortOrder);
    setSortField(sortField);
    setOrdenado(sortField);
  };

  useEffect(() => {
    getUsers().then(() => setLoading(false));
  }, [page, search, ordenado, sortOrder, user_type, access_type, is_active]);

  const getUsers = async () => {
    setLoading(true);

    if (access_type === "Admin") {
      await api
        .get(
          `users?page=${page}&search=${search}&orderBy=${ordenado}&order=${sortOrder}&user_type=${user_type}&is_active=${is_active}`
        )
        .then((response) => {
          setUsers(response.data.data);
          setCounter(response.data.total);
        })
        .finally(() => setLoading(false));
    }
  };

  const getUserCompanies = async (userId) => {
    setLoading(true);
    try {
      const response = await api.get(`/users/${userId}/companies`);
      return response.data;
    } catch (error) {
      console.error("Error fetching user companies", error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const getDialogWidth = () => {
    if (windowWidth < 767) {
      return 500;
    } else {
      return 1000;
    }
  };

  const unsetData = () => {
    const unsetData = [];

    unsetData.forEach((data) => data(""));

    setProductDialog(false);
  };

  const openNew = async (rowData) => {
    document.body.style.position = "fixed";
    setProductDialog(true);
    await getCompanies();
  };

  const hideDialog = () => {
    document.body.style.position = null;

    setError("");
    unsetData();
  };

  const saveProduct = () => {
    setProductDialog(false);
  };

  const exportCSV = async () => {
    setLoading(true);

    api
      .get("/companies/export-excel", {
        responseType: "blob"
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const owner_company = owner_company.split(" ").join("-");
        link.setAttribute("download", "empresas-" + owner_company + ".xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Novo"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={openNew}
        />
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exportar"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const handleCloseCreateDialog = () => {
    setProductDialog(false);
    setIsCostumer(false);
    setIsTech(false);
    setCarPlate("");
    setProductDialog(false);
    setNewUserEmail("");
    setNewUserType("");
    setNewUserName("");
    setNomeEmpresa("");
    setSingleCompany("");
    setNewUserType("Administrador");
    setUserCompanies([]);
    document.body.style.position = null;
  };

  const handleCloseEditDialog = () => {
    document.body.style.position = null;

    setIsCostumer(false);
    setIsTech(false);
    setCarPlate("");
    setActiveUserType("Administrador");
    setEditUserDialog(false);
    setNewUserEmail("");
    setNewUserName("");
    setUserTelephone(null);
    setUserCompanies([]);
  };

  const [editUserDialog, setEditUserDialog] = useState(false);

  const [activeUser, setActiveUser] = useState("");

  const handleModalEdit = async (rowData) => {
    setLoading(true);
    document.body.style.position = "fixed";
    await getCompanies();

    const companies = await getUserCompanies(rowData.id);
    setUserCompanies(companies);

    setActiveUser(rowData);
    setEditUserDialog(true);
    setCarPlate(rowData?.car_plate);
    setNewUserEmail(rowData?.email);
    setUserTelephone(rowData?.phone || null);
    setNewUserName(rowData?.name);

    getActiveUserType(rowData.user_type);
    setLoading(false);
  };

  const history = useHistory();

  const actionBodyTemplate = (rowData) => {
    const icon = rowData.is_active ? "pi pi-trash" : "pi pi-angle-double-up";
    const tooltip = rowData.is_active ? "Inativar" : "Ativar";
    const func = rowData.is_active ? handleDeactivateUser : handleActivateUser;
    const color = rowData.is_active ? "#9E3131" : "#003B17";
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          tooltip="Editar"
          tooltipOptions={{ position: "top" }}
          className="p-button-rounded p-button-warning text-white p-mr-2 me-2"
          onClick={() => handleModalEdit(rowData)}
        />
        {/* botao que inativa usuario */}
        <Button
          icon={icon}
          tooltip={tooltip}
          tooltipOptions={{ position: "top" }}
          style={{ backgroundColor: color, outline: 0, border: "none" }}
          className="p-button-rounded p-button-warning text-white p-mr-2 me-2"
          onClick={() => func(rowData.id)}
        />
        {access_type === "Admin" && (
          <Button
            icon="pi pi-list"
            tooltip={"Ver logs"}
            tooltipOptions={{ position: "top" }}
            style={{ backgroundColor: "purple", outline: 0, border: "none" }}
            className="p-button-rounded p-button text-white"
            onClick={() =>
              history.push("/logs/" + rowData.id, { user: rowData })
            }
          />
        )}
      </React.Fragment>
    );
  };

  const items = [
    {
      label: "Filtrar",
      items: [
        {
          label: "Administrador",
          command: () => {
            setUserType("Admin");
            setPage(1);
          }
        },
        {
          label: "Técnico",
          command: () => {
            setUserType("Tech");
            setPage(1);
          }
        },
        {
          label: "Cliente",
          command: () => {
            setUserType("Costumer");
            setPage(1);
          }
        },
        {
          label: "Todos",
          command: () => {
            setUserType("");
            setPage(1);
          }
        },
        {
          label: "Usuários " + (is_active ? "Inativos" : "Ativos"),
          command: () => {
            setIsActive(is_active === 1 ? 0 : 1);
            setPage(1);
          }
        }
      ]
    }
  ];

  const menu = useRef(null);

  const header = (
    <div className="d-flex flex-row align-content-center justify-content-between">
      <div>
        <h5 className="p-m-0">Todos os usuários</h5>
        <span className="p-input-icon-left" style={{ marginRight: 8 }}>
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => {
              setSearch(e.target.value);
              setPage(1);
            }}
            placeholder="Busca..."
          />
        </span>
        {access_type === "Admin" && (
          <>
            <Menu model={items} popup ref={menu} id="popup_menu" />
            <Button
              icon="pi pi-filter text-white"
              onClick={(event) => menu.current.toggle(event)}
              aria-controls="popup_menu"
              aria-haspopup
            />
          </>
        )}
      </div>
    </div>
  );

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const [newUserType, setNewUserType] = useState("Administrador");
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [userTelephone, setUserTelephone] = useState(null);
  const [userCompanies, setUserCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleActivateUser = async (id) => {
    setLoading(true);
    await api
      .get("/users/activate/" + id)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "",
          detail: "Ativado usuário com sucesso",
          life: 5000
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail: "Erro ao ativar usuário",
          life: 5000
        });
      })
      .finally(async () => {
        await getUsers();
      });
  };

  const handleDeactivateUser = async (id) => {
    setLoading(true);
    await api
      .get("/users/deactivate/" + id)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "",
          detail: "Desativado usuário com sucesso",
          life: 5000
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail: "Erro ao desativar usuário",
          life: 5000
        });
      })
      .finally(async () => {
        await getUsers();
      });
  };

  const handleDeleteUserCompany = async (user_id, company_id) => {
    try {
      const response = await api.delete("users/deleteUserCompany", {
        data: {
          user_id: user_id,
          company_id: company_id
        }
      });

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Sucesso",
          detail: "Relação entre usuário e empresa deletada com sucesso",
          life: 3000
        });
        const updatedCompanies = await getUserCompanies(user_id);
        setUserCompanies(updatedCompanies);
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Aviso",
          detail: response.data.message,
          life: 3000
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao deletar a relação entre usuário e empresa",
        life: 3000
      });
    }
  };

  const handleAddUserCompany = async () => {
    if (!selectedCompany) {
      toast.current.show({
        severity: "warn",
        summary: "",
        detail: "Selecione uma empresa para adicionar",
        life: 3000
      });
      return;
    }

    try {
      const response = await api.post("users/createUserCompany", {
        user_id: activeUser.id,
        company_id: selectedCompany
      });

      if (response.status === 201) {
        toast.current.show({
          severity: "success",
          summary: "Sucesso",
          detail: "Relação entre usuário e empresa adicionada com sucesso",
          life: 3000
        });
        const updatedCompanies = await getUserCompanies(activeUser.id);
        setUserCompanies(updatedCompanies);
        setSelectedCompany(null);
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Aviso",
          detail: response.data.message,
          life: 3000
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao adicionar a relação entre usuário e empresa",
        life: 3000
      });
    }
  };

  const handleCreateUser = async () => {
    if (!newUserEmail || !newUserType || !newUserName) {
      toast.current.show({
        severity: "warn",
        summary: "",
        detail: "Insira todos os campos",
        life: 5000
      });
      return;
    }

    const splited = newUserName.split(" ");

    if (splited[0].length < 2 || !splited[1]) {
      toast.current.show({
        severity: "warn",
        summary: "",
        detail: "Coloque o nome completo",
        life: 5000
      });
      return;
    }

    if (newUserType === "Cliente" && !id_empresa) {
      toast.current.show({
        severity: "warn",
        summary: "",
        detail: "Selecione uma empresa",
        life: 5000
      });
      return;
    }

    if (!emailIsValid(newUserEmail)) {
      toast.current.show({
        severity: "error",
        summary: "",
        detail: "E-mail inválido",
        life: 5000
      });
      setLoading(false);
      return;
    }

    setLoading(true);
    let user_type = "";
    if (newUserType === "Administrador") {
      user_type = "Admin";
    } else if (newUserType === "Técnico") {
      user_type = "Tech";
    } else {
      user_type = "Costumer";
    }

    setProductDialog(false);
    setNewUserEmail("");
    setNewUserType("Administrador");
    setNewUserName("");
    setNomeEmpresa("");
    setSingleCompany("");
    setIdEmpresa("");
    setIsCostumer(false);
    setIsTech(false);
    setCarPlate("");
    document.body.style.position = null;

    const data = {
      name: newUserName,
      email: newUserEmail,
      user_type: user_type
    };

    if (user_type === "Costumer") {
      data.company_id = id_empresa;
    }

    if (data.user_type === "Tech") {
      data.car_plate = car_plate;
    }

    try {
      const response = await api.post("/users/create", data);
      toast.current.show({
        severity: "success",
        summary: "",
        detail: `Criado usuário ${response?.data?.user?.name} com sucesso. Utilize a senha: ${response.data.user.password} para realizar o login.`,
        life: 15000
      });
    } catch (e) {
      if (e.request.status === 302) {
        toast.current.show({
          severity: "error",
          summary: "",
          detail: "Erro ao criar usuário, e-mail já cadastrado no sistema",
          life: 5000
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "",
          detail: "Erro ao criar usuário",
          life: 5000
        });
      }
    }
    setLoading(false);
  };

  const userDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={handleCloseCreateDialog}
      />
      <Button
        label="Salvar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleCreateUser}
      />
    </React.Fragment>
  );

  const handleEditUserType = async () => {
    document.body.style.position = null;

    let user_type = activeUserType;

    if (user_type === "Administrador") {
      user_type = "Admin";
    } else if (user_type === "Técnico") {
      user_type = "Tech";
    } else {
      user_type = "Costumer";
    }

    let data = {
      user_type: user_type
    };

    if (data.user_type === "Costumer") {
      data.company_id = id_empresa;
    }
    if (data.user_type === "Tech") {
      if (!emailIsValid(newUserEmail)) {
        toast.current.show({
          severity: "error",
          summary: "",
          detail: "E-mail inválido",
          life: 5000
        });
        setLoading(false);
        return;
      }

      data.car_plate = car_plate;
      data.name = newUserName;
      data.email = newUserEmail;
    }

    setEditUserDialog(false);

    setLoading(true);

    setIsTech(false);
    setCarPlate("");
    setNewUserName("");
    setNewUserEmail("");
    setUserTelephone(null);
    setUserCompanies([]);
    setSelectedCompany(null);

    try {
      await api.post("/users/changeUserType/" + activeUser.id, data);
      toast.current.show({
        severity: "success",
        summary: "",
        detail: "Alterado usuário com sucesso",
        life: 5000
      });
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "",
        detail: "Erro ao criar usuário",
        life: 5000
      });
    }
    await getUsers();
    setLoading(false);
  };

  const userEditDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={handleCloseEditDialog}
      />
      <Button
        label="Salvar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleEditUserType}
      />
    </React.Fragment>
  );

  const handleChangeOption2 = (option) => {
    if (option.value === "Cliente") {
      setIsCostumer(true);
      setIsTech(false);
    } else if (option.value === "Técnico") {
      setIsTech(true);
      setIsCostumer(false);
    } else {
      setIsTech(false);
      setIsCostumer(false);
    }
    setNewUserType(option.value);
  };

  const [activeUserType, setActiveUserType] = useState("");

  const getActiveUserType = (user_type) => {
    if (user_type === "Admin") {
      setActiveUserType("Administrador");
      return "Administrador";
    } else if (user_type === "Tech") {
      setActiveUserType("Técnico");
      setIsTech(true);
      return "Técnico";
    } else {
      setActiveUserType("Cliente");
      setIsCostumer(true);
      return "Cliente";
    }
  };

  const templateSelectPrime = (option) => {
    return <div style={{ maxWidth: "500px" }}>{option.label}</div>;
  };

  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [id_empresa, setIdEmpresa] = useState("");
  const [empresas, setEmpresas] = useState([]);
  const [is_costumer, setIsCostumer] = useState(false);

  const handleChangeSelect = (selectedOption) => {
    setIdEmpresa(selectedOption.value);
    options.forEach((e) => {
      if (e.value === selectedOption.value) {
        setNomeEmpresa(e.label);
        return;
      }
    });
  };

  const [options, setOptions] = useState([{ value: "", label: "" }]);
  let alterEmpresa = [];

  const getCompanies = async () => {
    await api
      .get(`companies/all`)
      .then((response) => {
        setEmpresas(response.data);
        alterEmpresa = response.data;
        const newArr = [];
        newArr.push(
          ...alterEmpresa.map((empresa) => ({
            value: empresa.id,
            label: empresa.name
          }))
        );

        setOptions(newArr);
      })
      .finally(() => setLoading(false));
  };

  const handleChangeOption = (option) => {
    if (option.value === "Cliente") {
      setIsCostumer(true);
      setIsTech(false);
    } else if (option.value === "Técnico") {
      setIsTech(true);
      setIsCostumer(false);
    } else {
      setIsTech(false);
      setIsCostumer(false);
    }
    setActiveUserType(option.value);
  };

  const [is_tech, setIsTech] = useState(false);
  const [car_plate, setCarPlate] = useState("");

  return (
    <>
      <div className="datatable-responsive-demo">
        <Toast ref={toast} position="bottom-right" />

        <div className="card">
          <Toolbar className="p-mb-4" left={leftToolbarTemplate} />

          <DataTable
            ref={dt}
            value={users}
            dataKey="id"
            rows={10}
            header={header}
            sortOrder={sortOrder}
            sortField={sortField}
            onSort={({ sortField, sortOrder }) => ordenar(sortField, sortOrder)}
            className={"p-datatable-responsive-demo"}
            loading={loading}
          >
            <Column headerStyle={{ width: "3rem" }} />
            <Column field="name" header={"Nome"} sortable />
            <Column field="email" header="E-mail" sortable />
            <Column
              sortable
              field="user_type"
              header="Nível"
              body={(rowData) =>
                rowData.user_type === "Tech"
                  ? "Técnico"
                  : rowData.user_type === "Admin"
                  ? "Administrador"
                  : "Cliente"
              }
            />
            <Column header="Ações" body={actionBodyTemplate} />
          </DataTable>
          <div
            className={"d-flex justify-content-center align-items-center p-3"}
          >
            <Pagination
              className="pagination"
              color={"primary"}
              boundaryCount={1}
              count={Math.ceil(counter / 9)}
              onChange={handleChangePage}
            />
          </div>
        </div>
      </div>

      <Dialog
        visible={productDialog}
        style={{ width: getDialogWidth() + "px" }}
        header="Novo usuário"
        modal
        footer={userDialogFooter}
        onHide={handleCloseCreateDialog}
      >
        <div className={"d-flex flex-column"}>
          <div className={"row"}>
            <div className="col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center">
              <form
                className={
                  "d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center"
                }
                onSubmit={() => {}}
              >
                <div className={"row pb-1"}>
                  <div className={"col-md-12 pb-3"}>
                    <label htmlFor="Cidade">Nome completo: </label>
                    <InputText
                      value={newUserName}
                      onChange={(e) => setNewUserName(e.target.value)}
                    />
                  </div>

                  <div className={"col-md-12 pb-3"}>
                    <label htmlFor="Cidade">Email: </label>
                    <InputText
                      value={newUserEmail}
                      onChange={(e) => setNewUserEmail(e.target.value)}
                    />
                  </div>

                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="MaquinaParada">Tipo do usuário</label>
                    <Dropdown
                      value={newUserType}
                      options={["Administrador", "Cliente", "Técnico"]}
                      onChange={handleChangeOption2}
                      style={{ height: "100%", alignItems: "center" }}
                    />
                  </div>

                  {is_costumer && (
                    <div className={"col-md-12 pb-3 d-flex flex-column"}>
                      <label htmlFor="Empresa">Empresa: </label>
                      <Dropdown
                        value={nomeEmpresa}
                        itemTemplate={templateSelectPrime}
                        valueTemplate={nomeEmpresa}
                        options={options}
                        onChange={handleChangeSelect}
                        filter
                        optionLabel={"label"}
                        optionValue={"value"}
                        placeholder="Selecione a empresa"
                        style={{ height: "50px", alignItems: "center" }}
                      />
                    </div>
                  )}
                  {is_tech && (
                    <div className={"col-md-12 pb-3"}>
                      <label htmlFor="Cidade">Placa do carro: </label>
                      <InputText
                        value={car_plate}
                        onChange={(e) => setCarPlate(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={editUserDialog}
        style={{ width: getDialogWidth() + "px" }}
        header="Editar usuário"
        modal
        footer={userEditDialogFooter}
        onHide={handleCloseEditDialog}
      >
        <div className={"d-flex flex-column"}>
          <div className={"row"}>
            <div className="col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center">
              <form
                className={
                  "d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center"
                }
                onSubmit={() => {}}
              >
                <div className={"row pb-1"}>
                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="MaquinaParada">Tipo do usuário</label>
                    <Dropdown
                      value={activeUserType}
                      options={["Administrador", "Cliente", "Técnico"]}
                      onChange={handleChangeOption}
                      style={{ height: "100%", alignItems: "center" }}
                    />
                  </div>

                  {is_costumer && (
                    <div className={"col-md-12 pb-3 d-flex flex-column"}>
                      <label htmlFor="Empresa">mudar empresa: </label>
                      <Dropdown
                        value={nomeEmpresa}
                        itemTemplate={templateSelectPrime}
                        valueTemplate={nomeEmpresa}
                        options={options}
                        onChange={handleChangeSelect}
                        filter
                        optionLabel={"label"}
                        optionValue={"value"}
                        placeholder="Selecione a empresa"
                        style={{ height: "50px", alignItems: "center" }}
                      />
                    </div>
                  )}
                  {is_tech && (
                    <>
                      <div className={"col-md-12 pb-3"}>
                        <label htmlFor="Cidade">Nome completo: </label>
                        <InputText
                          value={newUserName}
                          onChange={(e) => setNewUserName(e.target.value)}
                        />
                      </div>

                      <div className={"col-md-12 pb-3"}>
                        <label htmlFor="Cidade">Email: </label>
                        <InputText
                          value={newUserEmail}
                          onChange={(e) => setNewUserEmail(e.target.value)}
                        />
                      </div>
                      <div className={"col-md-12 pb-3"}>
                        <label htmlFor="Cidade">Placa do carro: </label>
                        <InputText
                          value={car_plate}
                          onChange={(e) => setCarPlate(e.target.value)}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className={"col-md-12 pb-3"}>
                  <label htmlFor="Nome">Nome: </label>
                  <InputText value={newUserName} disabled />
                </div>
                <div className={"col-md-12 pb-3"}>
                  <label htmlFor="Email">Email: </label>
                  <InputText value={newUserEmail} disabled />
                </div>
                <div className={"col-md-12 pb-3"}>
                  <label htmlFor="Telefone">Telefone: </label>
                  <InputText
                    value={masks.phone(userTelephone || "")}
                    disabled
                  />
                </div>
                {is_costumer && (
                  <div>
                    <div className="col-md-12 pb-3 d-flex flex-column">
                      <label htmlFor="Empresa">Adicionar nova empresa: </label>
                      <div className="d-flex gap-2 align-items-center">
                        <Dropdown
                          value={selectedCompany}
                          options={options}
                          onChange={(e) => setSelectedCompany(e.value)}
                          filter
                          optionLabel={"label"}
                          optionValue={"value"}
                          placeholder="Selecione a empresa"
                          className="flex-grow-1"
                          style={{ height: "50px", alignItems: "center" }}
                        />
                        <Button
                          label="Adicionar"
                          icon="pi pi-check"
                          className="p-button-success"
                          onClick={(e) => {
                            e.preventDefault();
                            handleAddUserCompany();
                          }}
                        />
                      </div>
                    </div>

                    <div className={"col-md-12 pb-3"}>
                      <DataTable value={userCompanies} dataKey="id">
                        <Column field="name" header="Nome da Empresa" />
                        <Column
                          header="Ação"
                          body={(rowData) => (
                            <Button
                              label="Deletar"
                              icon="pi pi-ban"
                              disabled={userCompanies.length <= 1}
                              className="p-button-danger"
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteUserCompany(
                                  activeUser.id,
                                  rowData.id
                                );
                              }}
                            />
                          )}
                        />
                      </DataTable>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Table;
