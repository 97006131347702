import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// if (process.env.REACT_APP_APP_ENV !== "local") {
// Sentry.init({
//   dsn: "https://de7baeb8cf31d3fa92742284efa131f2@o1073553.ingest.sentry.io/4505965043515392",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         "localhost",
//         /^https:\/\/chamados.grupomov.com.br/,
//         "https://chamados.grupomov.com.br/"
//       ]
//     }),
//     new Sentry.Replay()
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
