import React from "react";

export const dataConstruct = (rowData) => {
  const data = rowData.created_at;

  if (!data) {
    return "";
  }

  const date = new Date(data);
  return `${date.toLocaleDateString("pt-BR")} - ${date.toLocaleTimeString(
    "pt-BR"
  )}`;
};

export const tableData = [
  {
    name: "description",
    header: "Decrição"
  },
  {
    name: "route",
    header: "Rota"
  },
  {
    name: "created_at",
    header: "Data e Hora",
    body: dataConstruct
  },
  {
    name: "Ações",
    header: "Ações"
  }
];
