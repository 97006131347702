import React from "react";
import { Calendar } from "primereact/calendar";

const DateRangeDashboard = ({
  inputBeginDate,
  setInputBeginDate,
  beginDate,
  setBeginDate,
  setEndDate,
  endDate,
  inputEndDate,
  setInputEndDate
}) => {
  const handleChangeBeginDate = (e) => {
    const date = e.value || e;
    if (date) {
      setInputBeginDate(date);
      setBeginDate(date);
    }
  };

  const handleChangeEndDate = (e) => {
    const date = e.value || e;
    if (date) {
      setInputEndDate(date);
      setEndDate(date);
    }
  };

  return (
    <div className="p-field pt-2">
      <h5 className="p-m-0">Período</h5>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div className={"chamados-table-header-periodo"}>
          <div>
            <small>Início</small>
            <Calendar
              style={{ width: "100%" }}
              mask="99/99/9999"
              value={inputBeginDate}
              onChange={handleChangeBeginDate}
            />
          </div>
          <div>
            <small>Fim</small>
            <Calendar
              style={{ width: "100%" }}
              mask="99/99/9999"
              value={inputEndDate}
              onChange={handleChangeEndDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangeDashboard;
