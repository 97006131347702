import { Dialog } from "primereact/dialog";
import { Fade } from "@material-ui/core";
import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";

export default function ChangeAttendanceTime({
  visible = true,
  onHide = () => {},
  attendance,
  onSubmit
}) {
  const parseTime = (timeString, fallback = "00:00") =>
    timeString
      ? new Date(`1970-01-01T${timeString.trim()}:00`)
      : new Date(`1970-01-01T${fallback}:00`);

  const [startAttendanceTime, setStartAttendanceTime] = useState(() =>
    parseTime(attendance?.split("-")[0])
  );
  const [endAttendanceTime, setEndAttendanceTime] = useState(() =>
    parseTime(attendance?.split("-")[1])
  );

  useEffect(() => {
    setStartAttendanceTime(parseTime(attendance?.split("-")[0]));
    setEndAttendanceTime(parseTime(attendance?.split("-")[1]));
  }, [attendance]);

  const footer = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={onHide}
      />
      <Button
        label={"Salvar"}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => onSubmit(startAttendanceTime, endAttendanceTime)}
      />
    </>
  );

  return (
    <Dialog
      visible={visible}
      style={{ marginTop: 60, width: "80rem" }}
      className="p-fluid"
      modal
      onHide={onHide}
      header="Mudar horário de atendimento"
      footer={footer}
    >
      <Fade in={true}>
        <div className="container pt-2">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center">
              <form className="d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center">
                <div className="row pb-1">
                  <div className="col-md-6 d-flex flex-column">
                    <label htmlFor="startTime">Hora de início</label>
                    <Calendar
                      value={startAttendanceTime}
                      onChange={(e) => setStartAttendanceTime(e.value)}
                      timeOnly
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-column">
                    <label htmlFor="endTime">Hora final</label>
                    <Calendar
                      value={endAttendanceTime}
                      onChange={(e) => setEndAttendanceTime(e.value)}
                      timeOnly
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fade>
    </Dialog>
  );
}
