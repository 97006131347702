import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { createContext, useContext, useRef, useState } from "react";
import rejectImage from "../../assets/rejectImage.png";
import api from "../../services/api";
import { tableData } from "./ConstData";

const PartsContext = createContext({});

export default function PartsProvider({ children }) {
  const toast = useRef(null);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const getFromApi = ({
    search = "",
    page = 1,
    orderBy = "",
    order = -1,
    rows = 10,
    is_active = 1,
    setCounter = () => {}
  }) => {
    setLoading(true);
    api
      .get(
        `/parts/paginated?page=${page}&search=${search}&orderBy=${orderBy}&order=${order}&rows=${rows}&is_active=${is_active}`
      )
      .then((response) => {
        setItems(response.data.data);
        setCounter(response.data.total);
      })
      .finally(() => setLoading(false));
  };

  const createPart = (payload) => {
    setLoading(true);
    api
      .post(`/parts/create`, payload)
      .then((response) => {
        if (!response.data || response?.data?.errors) {
          for (let i in response.errors) {
            toast.current.show({
              severity: "error",
              summary: "",
              detail: response.errors[i],
              life: 7000
            });
          }
          return;
        }
        toast.current.show({
          severity: "success",
          summary: "",
          detail: "Criado com sucesso.",
          life: 7000
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail: "Erro ao criar.",
          life: 7000
        });
      })
      .finally(() => {
        setLoading(false);
        getFromApi({});
      });
  };

  const updatePart = (payload, part_id) => {
    setLoading(true);
    api
      .put(`/parts/update/${part_id}`, payload)
      .then((response) => {
        if (!response.data || response?.data?.errors) {
          for (let i in response.errors) {
            toast.current.show({
              severity: "error",
              summary: "",
              detail: response.errors[i],
              life: 7000
            });
          }
          return;
        }
        toast.current.show({
          severity: "success",
          summary: "",
          detail: "Alterado com sucesso.",
          life: 7000
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail: "Erro ao alterar.",
          life: 7000
        });
      })
      .finally(() => {
        setLoading(false);
        setSelectedItem({});
        getFromApi({});
      });
  };

  const deletePart = (part_id) => {
    closeDeleteDialog();
    setLoading(true);
    api
      .delete(`/parts/delete/${part_id}`)
      .then(() => {
        return toast.current.show({
          severity: "success",
          summary: "",
          detail: `Inativado com sucesso!`,
          life: 7000
        });
      })
      .catch(() =>
        toast.current.show({
          severity: "error",
          summary: "",
          detail: `Ocorreu um erro ao inativar`,
          life: 7000
        })
      )
      .finally(() => {
        setLoading(false);
        getFromApi({});
      });
  };

  const closeDeleteDialog = () => {
    setSelectedItem({});
    setDeleteDialog(false);
  };

  const contextData = {
    items,
    setItems,
    selectedItem,
    setSelectedItem,
    loading,
    setLoading,
    deleteDialog,
    setDeleteDialog,
    closeDeleteDialog,
    title: "Peças",
    tableData,
    getFromApi,
    createPart,
    updatePart,
    extraHeader: ({ is_active, ...props }) => (
      <Button
        {...props}
        label={is_active ? "Peças inativas" : "Peças ativas"}
        style={{ color: "white" }}
        onClick={() => props.setIsActive(is_active === 0 ? 1 : 0)}
      />
    )
  };

  const deleteDialogFooter = (
    <div className={"d-flex justify-content-around align-items-center"}>
      <Button
        label="Não"
        className="p-button-text text-white bg-danger w-50"
        style={{ height: 40, fontWeight: "bold" }}
        onClick={closeDeleteDialog}
      />
      <Button
        label="Sim"
        className="p-button-text text-white bg-success w-50"
        style={{ height: 40, fontWeight: "bold" }}
        onClick={() => deletePart(selectedItem.id)}
      />
    </div>
  );

  return (
    <PartsContext.Provider value={contextData}>
      <Toast ref={toast} position="bottom-right" />
      {children}
      <Dialog
        visible={deleteDialog}
        style={{ width: "450px" }}
        modal
        footer={deleteDialogFooter}
        onHide={closeDeleteDialog}
      >
        <div className={"d-flex flex-column align-items-center"}>
          <img
            width={"150"}
            className={"pb-3"}
            src={rejectImage}
            alt={"reject image"}
          />
          <h4 className={"text-dark"}>Deseja inativar essa peça?</h4>
          <small>
            Os relatórios que contém essa peça não constarão mais ela.
          </small>
        </div>
      </Dialog>
    </PartsContext.Provider>
  );
}

export function useParts() {
  return useContext(PartsContext);
}
