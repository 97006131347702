"use client";
import React, { createContext, useContext, useEffect, useState } from "react";
import api from "../../services/api";
import { tableData } from "./ConstData";
import { Button } from "primereact/button";
import ServiceOrderFilters from "../../components/ServiceOrderFilters/ServiceOrderFilters";

const ServiceOrderContext = createContext({});
const dateTypes = [
  {
    name: "created_at",
    label: "Criado em"
  },
  {
    name: "workshop_received_date",
    label: "Recebimento oficina"
  },
  {
    name: "closed_os_date",
    label: "Data de fechamento"
  }
];

export default function ServiceOrderProvider({ children }) {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [formModalOpened, setFormModalOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateType, setDateType] = useState(dateTypes[0]);
  const [showFilter, setShowFilter] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const getCompanies = async () => {
    const response = await api.get("/companies/all");
    setCompanies(response.data);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const getFromApi = ({
    search = "",
    page = 1,
    orderBy = "",
    order = -1,
    rows = 10,
    beginDate = "",
    endDate = "",
    status = "",
    companies = selectedCompanies,
    setCounter = () => {}
  } = {}) => {
    setLoading(true);
    const companiesParam = companies.join(",");

    api
      .get(
        `service_order?page=${page}&search=${search}&orderBy=${orderBy}&order=${order}&beginDate=${beginDate}&endDate=${endDate}&status=${status}&rows=${rows}&date_type=${dateType.name}&companies=${companiesParam}`
      )
      .then((response) => {
        setItems(response.data.data);
        setCounter(response.data.total);
      })
      .finally(() => setLoading(false));
  };

  const create = async (payload) => {
    setLoading(true);
    return api
      .post("service_order", payload)
      .then((response) => response)
      .catch((error) => error)
      .finally(() => setLoading(false));
  };

  const update = async (payload, id) => {
    setLoading(true);
    return api
      .put(`service_order/${id}`, payload)
      .then((response) => response)
      .catch((error) => error)
      .finally(() => setLoading(false));
  };

  const updateStatus = async (id, status) => {
    setLoading(true);
    return api
      .put(`/service_order/update-status/${id}`, { status })
      .then((response) => response)
      .catch((error) => error)
      .finally(() => setLoading(false));
  };

  const exportCSV = async ({
    search = "",
    beginDate = "",
    endDate = "",
    status = ""
  } = {}) => {
    setLoading(true);
    const companiesParam = selectedCompanies.join(",");
    api
      .get(
        `/service_order/export-excel?beginDate=${beginDate}&endDate=${endDate}&status=${status}&search=${search}&companies=${companiesParam}&date_type=${dateType.name}`,
        {
          responseType: "blob"
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "ordem-servico-" + new Date().getTime() + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const contextData = {
    items,
    setItems,
    selectedItem,
    setSelectedItem,
    loading,
    setLoading,
    tableData,
    getFromApi,
    title: "Ordem de serviço",
    create,
    update,
    updateStatus,
    formModalOpened,
    setFormModalOpened,
    exportFunction: exportCSV,
    dateType,
    setDateType,
    showFilter,
    setShowFilter,
    selectedCompanies,
    setSelectedCompanies,
    extraHeader: () => {
      return (
        <Button
          style={{ color: "white", marginLeft: 8 }}
          icon="pi pi-filter"
          onClick={() => setShowFilter(true)}
        />
      );
    }
  };

  return (
    <ServiceOrderContext.Provider value={contextData}>
      {children}
      <ServiceOrderFilters
        isVisible={showFilter}
        onHide={() => setShowFilter(false)}
        setMultiCompanies={setSelectedCompanies}
        companies={companies}
      />
    </ServiceOrderContext.Provider>
  );
}

export function useServiceOrder() {
  return useContext(ServiceOrderContext);
}
