"use client";
import QuantityCard from "../../components/QuantityCard";
import "./dashboard.css";
import { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { Dropdown } from "primereact/dropdown";
import DateRangeDashboard from "../../components/ToolbarComponents/DateRangeDashboard";
import { useQuery } from "react-query";
import api from "../../services/api";
import Loading from "../../components/Loading";
import { transformDayToHour } from "../../utils/transformer";

export default function Dashboard() {
  const currentDate = new Date();
  const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);

  const [selectClient, setSelectClient] = useState(null);
  const [beginDate, setBeginDate] = useState(firstDayOfYear);
  const [endDate, setEndDate] = useState(currentDate);
  const [inputBeginDate, setInputBeginDate] = useState(firstDayOfYear);
  const [inputEndDate, setInputEndDate] = useState(currentDate);

  const sumServiceCallsStatus = (status) => {
    if (!selectClient || !selectClient?.equipments) return 0;
    let sum = 0;
    for (const equipment of selectClient?.equipments) {
      sum += equipment.service_calls.filter(
        (call) => call?.status === status
      ).length;
    }
    return sum;
  };

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const { data, isLoading } = useQuery({
    queryKey: ["companies", beginDate, endDate, selectClient],
    queryFn: async () =>
      await api.get(
        `companies-dashboard?beginDate=${beginDate
          .toISOString()
          .slice(0, 10)}&endDate=${endDate?.toISOString().slice(0, 10) || ""}`
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (data) => {
      if (!selectClient) {
        setSelectClient(data.data[0]);
      } else {
        setSelectClient(
          data.data.find((company) => company.id === selectClient.id)
        );
      }
    }
  });

  const { data: serviceCallsMonthlyData } = useQuery({
    queryKey: ["serviceCallsMonthly", selectClient, endDate],
    queryFn: async () =>
      await api.get(
        `companies-dashboard/month-service-calls?company_id=${
          selectClient?.id
        }&endDate=${endDate.toISOString().slice(0, 10)}`
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const { data: averageDaysData } = useQuery({
    queryKey: ["averageDaysData", selectClient, beginDate, endDate],
    queryFn: async () =>
      await api.get(
        `companies-dashboard/average-days?company_id=${
          selectClient?.id
        }&beginDate=${beginDate.toISOString().slice(0, 10)}&endDate=${endDate
          .toISOString()
          .slice(0, 10)}`
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  useEffect(() => {
    const statusAberto = sumServiceCallsStatus("Aberto");
    const statusEmAndamento = sumServiceCallsStatus("Em andamento");
    const statusFechado = sumServiceCallsStatus("Fechado");
    const statusCancelado = sumServiceCallsStatus("Cancelado");
    const statusPendente =
      sumServiceCallsStatus("Pendente") +
      sumServiceCallsStatus("Pendente peça");

    const data = {
      labels: ["Aberto", "Em andamento", "Fechado", "Cancelado", "Pendente"],
      datasets: [
        {
          data: [
            statusAberto,
            statusEmAndamento,
            statusFechado,
            statusCancelado,
            statusPendente
          ],
          backgroundColor: ["#9E3131", "yellow", "#003B17", "gray", "#FF8B00"],
          hoverBackgroundColor: [
            "#9E3131",
            "yellow",
            "#003B17",
            "gray",
            "#FF8B00"
          ]
        }
      ]
    };
    const options = {
      cutout: "60%"
    };
    setChartData(data);
    setChartOptions(options);
  }, [selectClient]);

  const [qtdPatData, setQtdPatData] = useState({});
  const [qtdPatOptions, setQtdPatOptions] = useState({});

  useEffect(() => {
    const data = {
      labels:
        selectClient?.equipments?.map((equipment) => equipment?.pat) ?? [],
      datasets: [
        {
          label: "Quantidade",
          backgroundColor: "#0a7373",
          data:
            selectClient?.equipments?.map(
              (equipment) => equipment?.service_calls?.length
            ) ?? []
        }
      ]
    };
    const options = {
      indexAxis: "y",
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        x: {
          ticks: {
            stepSize: 1,
            callback: function (value) {
              if (Number.isInteger(value)) {
                return value;
              }
              return null;
            }
          },
          grid: {
            display: false,
            drawBorder: false
          }
        }
      }
    };
    setQtdPatData(data);
    setQtdPatOptions(options);
  }, [selectClient]);

  const companies = data?.data ?? [];

  const [qtdChamadosData, setQtdChamadosData] = useState({});
  const [qtdChamadosOptions, setQtdChamadosOptions] = useState({});

  useEffect(() => {
    if (!serviceCallsMonthlyData) return;

    const currentMonth = new Date().getMonth();

    const filteredMonthlyData = serviceCallsMonthlyData?.data?.filter(
      (i, index) => {
        return index <= currentMonth;
      }
    );

    const data = {
      labels: filteredMonthlyData?.map((i) => i?.month?.substr(0, 3)),
      datasets: [
        {
          label: "Quantidade",
          data: filteredMonthlyData?.map((i) => i?.count),
          fill: true,
          tension: 0.5,
          borderColor: "#eca81f",
          backgroundColor: "rgba(255, 167, 38, 0.2)"
        }
      ]
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        x: {
          ticks: {
            color: "black"
          },
          grid: {
            display: false
          }
        },
        y: {
          ticks: {
            color: "black"
          },
          grid: {
            display: false
          }
        }
      }
    };

    setQtdChamadosData(data);
    setQtdChamadosOptions(options);
  }, [serviceCallsMonthlyData]);

  const sumServiceCallsLength = () => {
    if (!selectClient || !selectClient?.equipments) return 0;
    let sum = 0;
    for (const equipment of selectClient?.equipments) {
      sum += equipment.service_calls.length;
    }
    return sum;
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="container-fluid dashboard-main">
        <div className="container first-half">
          <div className="dashboard-inputs">
            <div className="mb-2">
              <DateRangeDashboard
                inputBeginDate={inputBeginDate}
                setInputBeginDate={setInputBeginDate}
                beginDate={beginDate}
                setBeginDate={setBeginDate}
                endDate={endDate}
                setEndDate={setEndDate}
                inputEndDate={inputEndDate}
                setInputEndDate={setInputEndDate}
              />
            </div>
            <div className="d-flex flex-column">
              <h5 className="p-m-0">Cliente</h5>
              <Dropdown
                style={{ width: "100%" }}
                value={selectClient}
                onChange={(e) => setSelectClient(e.value)}
                options={companies}
                optionLabel="name"
                filter
                placeholder="Selecione um cliente"
              />
            </div>
          </div>
          <div>
            <table className="table table-dashboard table-striped">
              <thead>
                <tr>
                  <th>Equipamento</th>
                  <th>Qtd. Chamados</th>
                </tr>
              </thead>
              <tbody>
                {selectClient?.equipments
                  ?.slice()
                  .sort(
                    (a, b) =>
                      (b.service_calls?.length ?? 0) -
                      (a.service_calls?.length ?? 0)
                  )
                  .map((equipment, index) => (
                    <tr key={index}>
                      <td>
                        <h6>{equipment?.equipment}</h6>
                      </td>
                      <td>
                        <h6>{equipment?.service_calls?.length ?? 0}</h6>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="second-half">
          <div className="cards w-100 dashboard-cards">
            <QuantityCard
              className=""
              label="Chamados"
              isSelected={false}
              color="#blue"
              value={sumServiceCallsLength()}
            />
            <QuantityCard
              className=""
              label="Prazo Atend."
              isSelected={false}
              color="purple"
              value={
                averageDaysData?.data?.average_days_to_first_report
                  ? `${transformDayToHour(
                      averageDaysData?.data?.average_days_to_first_report
                    )}h`
                  : 0
              }
            />
            <QuantityCard
              className=""
              label="Prazo Res."
              isSelected={false}
              color="purple"
              value={
                averageDaysData?.data?.average_days_to_close
                  ? `${transformDayToHour(
                      averageDaysData?.data?.average_days_to_close
                    )}h`
                  : 0
              }
            />
          </div>
          <div className="graphics">
            <div className="graphics-first-second">
              <div className="d-flex flex-column">
                <hr />
                <h4 className="mt-2">Chamados</h4>
                <Chart
                  type="doughnut"
                  width="300px"
                  data={chartData}
                  options={chartOptions}
                />
              </div>
              <div>
                <hr />
                <h4>Qtd. Chamados por Equipamento</h4>
                <Chart type="bar" data={qtdPatData} options={qtdPatOptions} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <hr />
        <h4>Qtd. Chamados por mês</h4>
        <Chart
          type="line"
          data={qtdChamadosData}
          options={qtdChamadosOptions}
        />
      </div>
    </>
  );
}
