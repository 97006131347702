import { Dialog } from "primereact/dialog";
import { Fade } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useAuth } from "../../contexts/AuthContext";

export default function CheckedByAdm({
  checks,
  costumerChecks,
  visible = true,
  onHide = () => {},
  onSubmit
}) {
  const { email } = useAuth();
  const hideForm = () => {
    onHide();
  };

  const footer = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          onHide();
        }}
      />
      <Button
        label={"Verificar"}
        icon="pi pi-check"
        disabled={Boolean(
          checks?.find((check) => check?.validated_by?.email === email)
        )}
        className="p-button-text"
        onClick={onSubmit}
      />
    </React.Fragment>
  );

  const checkAdm = () => {
    return (
      <div>
        {(!checks || checks.length === 0) &&
          (!costumerChecks || costumerChecks.length === 0) && (
            <h6>Nenhuma validação realizada</h6>
          )}
        <div className="d-flex flex-column">
          {checks && checks.length > 0 && (
            <div>
              <h6>Validações do GrupoMOV:</h6>
              <div className="d-flex flex-column">
                <ul>
                  {checks?.map((check) => (
                    <li key={check?.id}>
                      {" "}
                      {check?.validated_by?.name} -{" "}
                      {new Date(check.created_at).toLocaleDateString("pt-BR")} |{" "}
                      {new Date(check.created_at).toLocaleTimeString("pt-BR")}{" "}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {costumerChecks && costumerChecks?.length > 0 && (
            <div>
              <h6>Validações do cliente:</h6>
              <div className="d-flex flex-column">
                <ul>
                  {costumerChecks?.map((check) => (
                    <li key={check?.id}>
                      {" "}
                      {check?.costumer_name} -{" "}
                      {new Date(check.validated_at).toLocaleDateString("pt-BR")}{" "}
                      |{" "}
                      {new Date(check.validated_at).toLocaleTimeString("pt-BR")}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <Dialog
        visible={visible}
        style={{ marginTop: 60, width: "80rem" }}
        className="p-fluid"
        modal
        onHide={hideForm}
        header={"Validação relatório"}
        footer={footer}
      >
        <div className={"pt-2"}> {checkAdm()} </div>
      </Dialog>
    </>
  );
}
