import React, { useEffect, useRef, useState } from "react";
import "./style.css";

import { Fade } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Input from "../Form/Input";
import { useOrcamento } from "../../contexts/OrcamentoContext";
import { Dropdown } from "primereact/dropdown";
import api from "../../services/api";
import ListOrcamentos from "../ListOrcamentos";
import TableListOrcamentos from "../ListOrcamentos/TableListOrcamentos";

const schema = z.object({
  company: z.object({ name: z.string(), id: z.number() }),
  equipment: z.object({ equipment: z.string(), id: z.number() }),
  service_order_id: z
    .number({ invalid_type_error: "Selecione uma ordem de serviço válida." })
    .optional()
    .nullable(),
  comments: z
    .string()
    .max(200, { message: "Observações devem ter menos de 200 caracteres." })
    .nullable(),
  parts: z.array(
    z.object({
      part_id: z
        .number({ invalid_type_error: "Selecione uma peça" })
        .positive({ message: "Selecione uma peça." }),
      quantity: z
        .number({ invalid_type_error: "Digite a quantidade." })
        .nonnegative()
        .positive({
          message: "Digite uma quantidade superior a 0."
        }),
      price: z
        .number({ invalid_type_error: "Digite o preço." })
        .nonnegative({ message: "digite um numero positivo" }),
      description: z.string()
    })
  )
});

export default function CreateOrcamentoForm({
  visible = true,
  onHide = () => {}
}) {
  let [empresas, setEmpresas] = useState([]);
  let [partsOptions, setPartsOptions] = useState([]);
  let [partsSelected, setPartsSelected] = useState([]);
  let [updatingOrcamento, setUpdatingOrcamento] = useState(false);
  let [serviceOrders, setServiceOrders] = useState([]);
  const toastError = useRef(null);

  useEffect(() => {
    api.get("companies/all").then((response) => {
      setEmpresas(response.data);
    });
    api.get("parts").then((response) => {
      setPartsOptions(response.data);
    });
    api.get("service_order/all-simple-service-orders").then((response) => {
      setServiceOrders(response.data.data);
    });
  }, []);

  const handleChangeSelect = (selectedOption) => {
    const company = empresas.find(
      (empresa) => empresa.id == selectedOption.value
    );
    if (company) {
      setValue("equipment", null);
      setValue("part", null);
      setValue("comments", null);
      return company;
    }
  };

  // selecionar equipamento
  const handleChangeSelectEquipment = (selectedOption) => {
    const equipment = watchEmpresa?.equipments.find(
      (equipment) => equipment.id == selectedOption.value
    );
    if (equipment) {
      return equipment;
    }
  };

  const handleChangeSelectServiceOrder = (selectedOption) => {
    const serviceOrder = serviceOrders.find(
      (serviceOrder) => serviceOrder.id == selectedOption.value
    );
    if (serviceOrder) {
      return serviceOrder;
    }
  };

  // selecionar peça
  const handleChangeSelectPart = (selectedOption, index) => {
    const selectedPart = partsOptions.find(
      (part) => part.id == selectedOption.value
    );
    if (selectedPart) {
      const currentParts = watch("parts");
      const newParts = [
        ...currentParts.slice(0, index),
        {
          part_id: selectedPart.id,
          quantity: 1,
          price: selectedPart.price,
          description: selectedPart.description
        },
        ...currentParts.slice(index + 1)
      ];
      setValue("parts", newParts);
      return selectedPart;
    }
  };

  const context = useOrcamento();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch
  } = useForm({
    resolver: zodResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      company: context?.selectedItem?.company || null,
      equipment: context?.selectedItem?.equipment || null,
      parts: context?.selectedItem?.parts || [],
      service_order_id: context?.selectedItem?.service_order_id || null
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "parts"
  });

  const watchEmpresa = watch("company");
  const watchParts = watch("parts");

  useEffect(() => {
    if (!context.selectedItem) {
      setValue("company", {});
      setValue("equipment", {});
      setValue("parts", []);
      setValue("comments", ``);
      setPartsSelected([]);
      return;
    }
    setValue("company", context.selectedItem?.company);
    setValue("equipment", context.selectedItem?.equipment);
    setValue("parts", context.selectedItem?.parts);
    setValue("comments", context.selectedItem?.observation);
    setValue(
      "service_order_id",
      context.selectedItem?.service_order_id ?? null
    );
    setPartsSelected(context.selectedItem?.parts);
    context?.selectedItem?.parts
      ? setUpdatingOrcamento(true)
      : setUpdatingOrcamento(false);
  }, [context.selectedItem, updatingOrcamento]);

  const watchResult = watch("parts");

  const submitForm = async (data) => {
    const payload = {
      company_id: data.company.id,
      equipment_id: data.equipment.id,
      observation: data.comments,
      parts: data.parts,
      service_order_id: data.service_order_id || null
    };
    if (updatingOrcamento) {
      hideForm();
      try {
        await context.updateOrcamento(payload, context.selectedItem.id);
        setUpdatingOrcamento(false);
      } catch (error) {
        console.log(error);
      }
      context.getFromApi({});
      return;
    } else if (payload.parts.length > 0) {
      hideForm();
      try {
        await context.createOrcamento(payload);
      } catch (error) {
        console.log(error);
      }
      context.getFromApi({});
      return;
    }
  };

  const hideForm = () => {
    setUpdatingOrcamento(false);
    reset();
    onHide();
  };

  const createChamadoAdmin = () => {
    return (
      <Fade in={true}>
        <div className="container">
          <Toast ref={toastError} />
          <div className={"row"}>
            <div className="col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center">
              <form
                onSubmit={handleSubmit(submitForm)}
                className={
                  "d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center"
                }
              >
                <div className={"row pb-1"}>
                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="Empresa">Empresa: </label>
                    <Controller
                      control={control}
                      name="company"
                      render={({ field: { onChange, ...field } }) => {
                        return (
                          <Dropdown
                            placeholder="Selecione a empresa"
                            options={empresas}
                            valueTemplate={field?.value?.name || ""}
                            onChange={(event) => {
                              const company = handleChangeSelect(event);
                              onChange(company);
                            }}
                            filter
                            optionLabel={"name"}
                            optionValue={"id"}
                            label={"Empresa"}
                            error={errors.company?.message}
                            {...field}
                          />
                        );
                      }}
                    />
                  </div>
                </div>

                <div className={"row pb-1"}>
                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="Equipamento">Equipamento: </label>
                    <Controller
                      control={control}
                      name="equipment"
                      render={({ field: { onChange, ...field } }) => (
                        <Dropdown
                          placeholder="Selecione um equipamento"
                          options={watchEmpresa?.equipments || []}
                          valueTemplate={field?.value?.equipment || ""}
                          onChange={(event) => {
                            const equipment =
                              handleChangeSelectEquipment(event);
                            onChange(equipment);
                          }}
                          optionLabel={"equipment"}
                          optionValue={"id"}
                          filter
                          label={"Equipamento"}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className={"row pb-1"}>
                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="Equipamento">Nº da OS:</label>

                    <Controller
                      control={control}
                      name="service_order_id"
                      render={({ field: { onChange, value } }) => (
                        <Dropdown
                          placeholder="Selecione uma ordem de serviço (opcional)"
                          options={serviceOrders.map((order) => ({
                            label: `OS ${order.service_number} - ${order.error_description}`,
                            value: order.id
                          }))}
                          value={value || null}
                          filter
                          onChange={(e) => onChange(e.value)}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className={"row pb-1"}>
                  <div className={"col-md-12 d-flex flex-column"}>
                    <Input
                      error={errors.comments?.message}
                      name={"comments"}
                      label={"Observações"}
                      register={register}
                    />
                  </div>
                </div>

                <div className={"row pb-1"}>
                  {partsSelected
                    ? fields.map((part, index) => {
                        const errorForField = errors?.parts?.[index];
                        return (
                          <div
                            key={part.id}
                            className={"col-md-12 pb-1 d-flex flex-column"}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="font-weight-bold d-inline">
                                <label htmlFor={`part-${index}`}>
                                  <h4>Peça {index + 1}:</h4>
                                </label>
                              </div>

                              <div className="d-inline">
                                <Button
                                  label="Remover"
                                  icon="pi pi-times "
                                  className="p-button-text text-danger"
                                  type="button"
                                  onClick={() => {
                                    if (watchParts.length > 1) {
                                      remove(index);
                                    } else {
                                      toastError.current.show({
                                        severity: "error",
                                        summary: "Erro",
                                        detail:
                                          "Não é possível excluir a única peça do orçamento."
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <Controller
                              control={control}
                              name={`parts.${index}.part_id`}
                              render={({ field: { onChange, ...field } }) => {
                                return (
                                  <>
                                    <Dropdown
                                      placeholder="Selecione uma peça"
                                      options={partsOptions}
                                      value={part.part_id}
                                      className={
                                        errors.parts?.[index]?.part_id
                                          ? "mb-2 border-danger"
                                          : "mb-2"
                                      }
                                      onChange={(event) =>
                                        handleChangeSelectPart(event, index)
                                      }
                                      optionLabel={"description"}
                                      optionValue={"id"}
                                      filter
                                      label={"Peça"}
                                    />
                                    {errors.parts?.[index]?.part_id && (
                                      <span className="text-danger">
                                        {errors.parts[index].part_id.message}
                                      </span>
                                    )}
                                  </>
                                );
                              }}
                            />
                            <div>
                              <div className={"col-md-12 d-flex flex-column"}>
                                <label htmlFor={`value-${index}`}>
                                  Quantidade:
                                </label>
                                <Controller
                                  control={control}
                                  name={`parts.${index}.quantity`}
                                  render={({ field: { onChange, value } }) => (
                                    <NumberFormat
                                      className={
                                        errors.parts?.[index]?.quantity
                                          ? "mb-2 border-danger"
                                          : "mb-2"
                                      }
                                      displayType="input"
                                      allowNegative={false}
                                      onValueChange={(text) =>
                                        onChange(parseFloat(text.value))
                                      }
                                      value={value}
                                      allowLeadingZeros={true}
                                    />
                                  )}
                                />
                                {errors.parts?.[index]?.quantity && (
                                  <span className="text-danger">
                                    {errors.parts[index].quantity.message}
                                  </span>
                                )}
                              </div>

                              <div className={"col-md-12 d-flex flex-column"}>
                                <label htmlFor={`value-${index}`}>Preço:</label>
                                <Controller
                                  control={control}
                                  name={`parts.${index}.price`}
                                  render={({ field: { onChange, value } }) => (
                                    <>
                                      <NumberFormat
                                        className={
                                          errors.parts?.[index]?.price
                                            ? "mb-2 border-danger"
                                            : "mb-2"
                                        }
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        prefix="R$ "
                                        displayType="input"
                                        allowNegative={false}
                                        defaultValue={0}
                                        onValueChange={(text) =>
                                          onChange(parseFloat(text.value))
                                        }
                                        value={value}
                                        allowLeadingZeros={true}
                                      />
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            {errors.parts?.[index]?.price && (
                              <span className="text-danger">
                                {errors.parts[index].price.message}
                              </span>
                            )}
                          </div>
                        );
                      })
                    : null}
                </div>
                <TableListOrcamentos parts={fields} />
                <div className="pb-1 d-flex justify-content-between ">
                  <div>
                    <Button
                      label="Adicionar peça"
                      icon="pi pi-plus"
                      type="button"
                      className="p-button-text text-primary "
                      onClick={() => {
                        setPartsSelected({ part_id: 0 });
                        append({
                          part_id: 0,
                          quantity: 1
                        });
                      }}
                    />
                  </div>

                  <div className="pb-1 d-flex justify-content-between">
                    <Button
                      label={updatingOrcamento ? "Editar" : "Criar"}
                      icon="pi pi-check"
                      className="p-button-text text-success "
                      type="submit"
                    />
                    <Button
                      label="Cancelar"
                      icon="pi pi-times"
                      type="button"
                      className="p-button-text text-danger "
                      onClick={() => {
                        hideForm();
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fade>
    );
  };

  return (
    <>
      <Dialog
        visible={visible}
        style={{ marginTop: 60, width: "80rem" }}
        header={"Orçamento"}
        modal
        className="p-fluid"
        onHide={hideForm}
      >
        <div className={"container pt-2"}>{createChamadoAdmin()}</div>
      </Dialog>
    </>
  );
}
