import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ContextTable from "../../components/ContextTable";
import { useOrcamento } from "../../contexts/OrcamentoContext";
import { withOrcamentoContext } from "../../contexts/OrcamentoContext/withOrcamento";
import CreateOrcamentoForm from "../../components/CreateOrcamentoForm";
import { tableData } from "../../contexts/OrcamentoContext/ConstData";
import ListOrcamentos from "../../components/ListOrcamentos";

const OrcamentoTable = () => {
  const context = useOrcamento();
  const [formModal, setFormModal] = useState(false);
  const [listModal, setListModal] = useState(false);

  const onHide = () => {
    context.setSelectedItem({});
    setFormModal(false);
    setListModal(false);
  };
  const openForm = (rowData = {}) => {
    context.setSelectedItem(rowData);
    setFormModal(true);
  };
  const openList = (rowData = {}) => {
    context.setSelectedItem(rowData);
    setListModal(true);
  };

  const defaultOrderBy = "code";

  const actionTemplate = (rowData) => {
    return (
      <>
        <Button
          tooltip="Editar"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning text-white p-mr-2 me-2"
          onClick={() => openForm(rowData)}
        />
        <Button
          tooltip="Excluir"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger text-white p-mr-2 me-2"
          onClick={() => {
            context.setSelectedItem(rowData);
            context.setDeleteDialog(true);
          }}
        />
        <Button
          tooltip="Ver peças"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-list"
          style={{ background: "green" }}
          className="p-button-rounded  text-white p-mr-2 me-2"
          onClick={() => openList(rowData)}
        />
        <Button
          tooltip="Ver peças"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-file-pdf"
          style={{
            backgroundColor: "#7f0b0b",
            outline: 0,
            border: "none"
          }}
          className="p-button-rounded p-button text-white"
          onClick={() =>
            window
              .open(
                `/pdf/orcamento_parts;${rowData.parts[0].orcamento_id}`,
                "_blank"
              )
              .focus()
          }
        />
      </>
    );
  };
  tableData.find((data) => data.header === "Ações").body = actionTemplate;

  return (
    <>
      <ContextTable
        context={context}
        openForm={openForm}
        defaultOrderBy={defaultOrderBy}
        hideForm={onHide}
      />
      <CreateOrcamentoForm onHide={onHide} visible={formModal} />
      <ListOrcamentos onHide={onHide} visible={listModal} />
      <Dialog
        visible={context.deleteDialog}
        style={{ marginTop: 60, width: "80rem" }}
        header={`Peças orçamento:`}
        modal
        className="p-fluid"
        onHide={() => {
          context.setDeleteDialog(false);
          context.setSelectedItem(null);
        }}
      >
        <div className="p-field">
          <label htmlFor="name">Deseja excluir o orçamento?</label>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            alignItems: "flex-end",
            justifyContent: "flex-end"
          }}
        >
          <Button
            onClick={() => context.deleteOrcamento(context.selectedItem.id)}
            className="p-button-danger"
            label="Excluir"
            style={{
              maxWidth: 100
            }}
          />
          <Button
            onClick={() => {
              context.setDeleteDialog(false);
              context.setSelectedItem(null);
            }}
            className="p-button-warning p-ml-2"
            label="Cancelar"
            style={{
              maxWidth: 100
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default withOrcamentoContext(OrcamentoTable);
