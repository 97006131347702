import React from "react";

import PartsProvider from "./index";

export const withPartsContext = (WrappedComponent) => (props) =>
  (
    <PartsProvider>
      <WrappedComponent {...props} />
    </PartsProvider>
  );
