import React from "react";
import DateRange2 from "./DateRange";
import { Dropdown } from "primereact/dropdown";
import Menu from "./HeaderMenu";
const Header = ({ title, context, ...props }) => {
  if (!context) return null;
  const ExtraHeader = context?.extraHeader;
  return (
    <div className="table-header chamados-table-header">
      <div className="pt-2">
        <h5 className="p-m-0">{title}</h5>
        <div className="d-flex gap-2">
          {context?.simple ? null : <Menu context={context} />}
          {context?.extraHeader ? <ExtraHeader {...props} /> : null}
        </div>
      </div>
      {props?.type ? (
        <div className="w-50 pt-2">
          <h5 className="p-m-0 ">Data por:</h5>
          <Dropdown
            value={props.dateType}
            options={props.dateTypes}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => {
              props.setDateType(e.value);
              props.ordenar(e.value);
            }}
          />
        </div>
      ) : null}
      <div className="">
        {context?.setBeginDate &&
        !context?.simple &&
        !context?.hideDateFilter ? (
          <DateRange2 context={context} />
        ) : null}
      </div>
    </div>
  );
};

export default Header;
