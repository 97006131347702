import Pagination from "@material-ui/lab/Pagination";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import RowsDropdown from "../RowsDropdown";
import Header from "./Header/Header";

const DateTypes = [
  {
    name: "created_at",
    label: "Criado em"
  },
  {
    name: "workshop_received_date",
    label: "Recebimento oficina"
  },
  {
    name: "closed_os_date",
    label: "Data de fechamento"
  },
  {
    name: "report_created",
    label: "Relatório criado"
  }
];

const ContextTable = ({
  type,
  context,
  openForm,
  hideForm,
  defaultOrderBy = "",
  defaultOrder = -1,
  id = null,
  selectedStatus = null,
  setFilterObject = () => null
}) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [order, setOrder] = useState(defaultOrder);
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [rows, setRows] = useState(10);
  const [counter, setCounter] = useState(0);
  const [isActive, setIsActive] = useState(1);
  const [dateType, setDateType] = useState(DateTypes[0].name);

  const dt = useRef();
  const { access_type } = useAuth();

  useEffect(() => {
    setFilterObject(getQueryObject());
  }, [search, page, orderBy, order, beginDate, endDate, status, rows]);

  const ordenar = (sortField, sortOrder = 1) => {
    setDateType(sortField);
    setOrder(sortOrder);
    setOrderBy(sortField);
    const dateType = DateTypes.find((type) => type.name === sortField);
    if (context?.setDateType && dateType) {
      context?.setDateType(dateType);
    }
  };

  useEffect(() => {
    if (selectedStatus !== null) {
      setStatus(selectedStatus);
    }
  }, [selectedStatus]);

  const getQueryObject = () => {
    return {
      search: search,
      page: page,
      orderBy: orderBy,
      beginDate: beginDate,
      endDate: endDate,
      status: status,
      rows: rows,
      setCounter,
      order: order,
      id,
      is_active: isActive
    };
  };

  const getFromApi = () => {
    const queryObject = getQueryObject();
    context.getFromApi(queryObject);
  };

  useEffect(() => {
    getFromApi();
  }, [
    search,
    page,
    orderBy,
    beginDate,
    endDate,
    status,
    rows,
    order,
    isActive,
    context?.dateType
  ]);

  const openFormDialog = () => {
    context.setSelectedItem({});
    openForm();
  };

  const LeftToolbarTemplate = () => {
    if (!context.setSelectedItem) return null;

    return (
      <React.Fragment>
        <Button
          label="Novo"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => openFormDialog(null)}
        />
      </React.Fragment>
    );
  };

  const RightToolbarTemplate = () => {
    if (!context.exportFunction) return null;

    return (
      <React.Fragment>
        <Button
          label="Exportar"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-upload"
          className="p-button-help"
          onClick={() => context.exportFunction(getQueryObject())}
        />
      </React.Fragment>
    );
  };

  return (
    <div className="card">
      <Toolbar
        className="p-mb-4"
        left={LeftToolbarTemplate}
        right={RightToolbarTemplate}
      />

      <DataTable
        ref={dt}
        value={context.items}
        dataKey="id"
        rows={rows}
        sortOrder={order}
        sortField={orderBy}
        onSort={({ sortField, sortOrder }) => ordenar(sortField, sortOrder)}
        emptyMessage="Nenhum registro encontrado"
        header={
          <Header
            sortOrder={order}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
            setDateType={setDateType}
            dateType={dateType}
            ordenar={ordenar}
            type={type}
            title={context.title}
            context={{
              setBeginDate,
              setEndDate,
              setPage,
              setSearch,
              simple: context.simple,
              extraHeader: context.extraHeader,
              hideDateFilter: context?.hideDateFilter
            }}
            dateTypes={DateTypes.map((type) => ({
              label: type.label,
              value: type.name
            }))}
            {...getQueryObject()}
            setIsActive={(item) => {
              setIsActive(item);
            }}
          />
        }
        className={"p-datatable-responsive-demo"}
        loading={context.loading}
      >
        <Column headerStyle={{ width: "3rem" }} />

        {context?.tableData.map((item) => (
          <Column
            headerStyle={item.headerStyle ? item.headerStyle : {}}
            field={item.name ? item.name : null}
            sortable={item.sortable}
            header={item.header}
            {...(item.body
              ? { body: (rowData) => item.body(rowData, access_type) }
              : {})}
          />
        ))}
      </DataTable>

      <div className={"d-flex justify-content-center align-items-center p-3"}>
        <div>
          <Pagination
            className="pagination"
            color={"primary"}
            boundaryCount={1}
            count={Math.ceil(counter / rows)}
            onChange={(event, newPage) => setPage(newPage)}
          />
        </div>
        {!context?.simple && <RowsDropdown rows={rows} setRows={setRows} />}
      </div>
    </div>
  );
};

export default ContextTable;
