import React, { useEffect } from "react";
import "./styles.css";

import { Fade } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm, Controller } from "react-hook-form";
import Input from "../Form/Input";
import { useParts } from "../../contexts/PartsContext";

const schema = z.object({
  code: z.string().nonempty({ message: "Digite o código" }),
  description: z.string().nonempty({ message: "Digite a descrição" }),
  price: z
    .number({
      required_error: "Digite o preço",
      invalid_type_error: "Digite o preço"
    })
    .nonnegative()
});

export default function CreatePecasForm({ visible = true, onHide = () => {} }) {
  const context = useParts();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control
  } = useForm({
    resolver: zodResolver(schema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      price: 0
    }
  });

  useEffect(() => {
    if (context.selectedItem && Object.keys(context.selectedItem).length > 0) {
      setValue("code", context.selectedItem.code);
      setValue("description", context.selectedItem.description);
      setValue("price", context.selectedItem.price);
    } else {
      setValue("code", "");
      setValue("description", "");
      setValue("price", 0);
    }
  }, [context.selectedItem]);

  const submitForm = async (data) => {
    onHide();

    if (context.selectedItem && Object.keys(context.selectedItem).length > 0) {
      await context.updatePart(data, context.selectedItem.id);
      return;
    }

    await context.createPart(data);
  };

  const hideForm = () => {
    reset();
    onHide();
  };

  const createChamadoAdmin = () => {
    return (
      <Fade in={true}>
        <div className="container">
          <div className={"row"}>
            <div className="col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center">
              <form
                className={
                  "d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center"
                }
              >
                <div className={"row pb-1"}>
                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <Input
                      name={"code"}
                      label={"Código"}
                      register={register}
                      error={errors.code?.message}
                    />
                  </div>
                </div>

                <div className={"row pb-1"}>
                  <div className={"col-md-12 d-flex flex-column"}>
                    <Input
                      name={"description"}
                      label={"Descrição"}
                      register={register}
                      error={errors.description?.message}
                    />
                  </div>
                </div>

                <div className={"row pb-1"}>
                  <div className={"col-md-12 d-flex flex-column"}>
                    <Controller
                      control={control}
                      name="price"
                      render={({ field: { onChange, value } }) => (
                        <>
                          <NumberFormat
                            className={
                              errors.price?.message
                                ? "mb-2 border-danger"
                                : "mb-2"
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            prefix="R$ "
                            displayType="input"
                            allowNegative={false}
                            onValueChange={(text) =>
                              onChange(parseFloat(text.value))
                            }
                            value={value}
                            allowLeadingZeros={true}
                          />
                          {errors.price?.message ? (
                            <span className={"text-danger"}>
                              {errors.price?.message}
                            </span>
                          ) : null}
                        </>
                      )}
                    />

                    {/*<Input name={'price'} label={'Preço'} register={register} error={errors.price?.message} />*/}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fade>
    );
  };

  const footer = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          hideForm();
        }}
      />
      <Button
        label="Salvar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleSubmit((data) => submitForm(data))}
      />
    </React.Fragment>
  );

  return (
    <>
      <Dialog
        visible={visible}
        style={{ marginTop: 60, width: "80rem" }}
        header={"Peça"}
        modal
        className="p-fluid"
        footer={footer}
        onHide={hideForm}
      >
        <div className={"container pt-2"}>{createChamadoAdmin()}</div>
      </Dialog>
    </>
  );
}
