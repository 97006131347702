import { Fade } from "@material-ui/core";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Fragment, useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useServiceOrder } from "../../contexts/ServiceOrderContext";
import api from "../../services/api";
import { constants } from "../../utils/constants";

const servicoArr = [
  "",
  "Frota",
  "Retorno de Locação",
  "Ordem Interna",
  "A faturar",
  "Garantia"
];

const statusArr = ["Aberto", "Em andamento", "Fechado"];

const ServiceOrderForm = ({ visible, onHide, toast, selectedStatus }) => {
  const context = useServiceOrder();
  const { access_type, email: loggedEmail } = useAuth();

  const [serviceType, setServiceType] = useState("");
  const [workshopReceivedDate, setWorkshopReceivedDate] = useState("");
  let [status, setStatus] = useState("");
  const [companyId, setCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [invoice, setInvoice] = useState("");
  const [hourMeter, setHourMeter] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [responsible, setResponsible] = useState("");
  const [comments, setComments] = useState("");
  const [request, setRequest] = useState("");
  const [closeOs, setCloseOs] = useState(false);
  const [deadline, setDeadline] = useState("");

  const [empresas, setEmpresas] = useState([{ value: "", label: "" }]);

  useEffect(() => {
    if (!visible) return;

    getCompanies();
  }, [access_type, visible, serviceType]);

  useEffect(() => {
    setSelectedItemValues();
  }, [context.selectedItem, visible]);

  const setSelectedItemValues = () => {
    if (!context.selectedItem || Object.keys(context.selectedItem).length === 0)
      return applyDefaultValues();

    setServiceType(context.selectedItem.service_type);
    setStatus(context.selectedItem.status);
    setWorkshopReceivedDate(
      new Date(context.selectedItem.workshop_received_date)
    );
    setCompanyId(context.selectedItem.company_id);
    setCompanyName(context.selectedItem?.company?.name);
    setEquipmentName(context.selectedItem?.equipment?.equipment);
    setEquipmentId(context.selectedItem.equipment_id);
    setInvoice(context.selectedItem.invoice);
    setHourMeter(context.selectedItem.hour_meter);
    setErrorDescription(context.selectedItem.error_description);
    setPhone(context.selectedItem.phone);
    setEmail(context.selectedItem.email);
    setResponsible(context.selectedItem.responsible);
    setComments(context.selectedItem.comments);
    setRequest(context.selectedItem.request);
    if (context.selectedItem.deadline) {
      setDeadline(new Date(context.selectedItem.deadline));
    }
    setDisableEquipment(false);
  };

  const getCompanies = () => {
    api.get("companies/all").then((response) => {
      let x = [];
      x.push(
        ...response.data.map((empresa) => ({
          value: empresa.company_id || empresa.id,
          label: empresa.name
        }))
      );
      setEmpresas(x);
    });
  };

  const onClose = () => {
    applyDefaultValues();
    onHide();
  };

  const applyDefaultValues = () => {
    setServiceType("");
    setWorkshopReceivedDate("");
    setCompanyName("");
    setCompanyId(0);
    setEquipmentName("");
    setEquipmentId(0);
    setDisableEquipment(true);
    setInvoice("");
    setHourMeter("");
    setErrorDescription("");
    setPhone("");
    setEmail("");
    setResponsible("");
    setComments("");
    setRequest("");
    setCloseOs(false);
    setDeadline("");
  };

  const create = async () => {
    context.setFormModalOpened(false);

    const workshopReceivedDatePayload = workshopReceivedDate
      ? new Date(workshopReceivedDate)
      : null;

    const deadlineDatePayload = deadline ? new Date(deadline) : null;

    const payload = {
      service_type: serviceType,
      workshop_received_date: workshopReceivedDatePayload
        ? workshopReceivedDatePayload?.toISOString()?.slice(0, 10)
        : "",
      deadline: deadlineDatePayload
        ? deadlineDatePayload?.toISOString()?.slice(0, 10)
        : "",
      company_id: companyId,
      equipment_id: equipmentId,
      invoice,
      hour_meter: hourMeter,
      error_description: errorDescription,
      phone,
      email,
      responsible,
      comments,
      request,
      close_os: closeOs
    };

    let response = null;

    if (context.selectedItem && Object.keys(context.selectedItem).length > 0) {
      response = await context.update(payload, context.selectedItem.id);

      if (status !== context.selectedItem.status) {
        await context.updateStatus(context.selectedItem.id, status);
      }
    } else {
      response = await context.create(payload);
    }

    if (response?.status === 201 || response?.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "",
        detail: "Ordem de serviço salva com sucesso!",
        life: 5000
      });
      onClose();
      context.getFromApi({ status: selectedStatus });
    } else if (response?.status === 422) {
      context.setFormModalOpened(true);
      toast.current.show({
        severity: "error",
        summary: "",
        detail: "Verifique os campos obrigatorios.",
        life: 5000
      });
    } else {
      context.setFormModalOpened(true);
      toast.current.show({
        severity: "warn",
        summary: "",
        detail: "Erro ao criar ordem de serviço.",
        life: 5000
      });
    }
  };

  const footer = (
    <Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={onClose}
      />
      <Button
        label={"Salvar"}
        icon="pi pi-check"
        className="p-button-text"
        onClick={create}
      />
    </Fragment>
  );

  const handleChangeServico = (option) => {
    if (option.value === "Frota") applyDefaultValues();

    setServiceType(option.value);
  };

  const [equipmentId, setEquipmentId] = useState(0);
  const [equipmentName, setEquipmentName] = useState("");
  const [disableEquipment, setDisableEquipment] = useState(true);

  const handleChangeSelect = async (selectedOption) => {
    setEquipmentId("");
    setEquipmentName("");
    setDisableEquipment(true);

    setCompanyId(selectedOption.value);
    empresas.forEach((e) => {
      if (e.value === selectedOption.value) {
        setCompanyName(e.label);
        return;
      }
    });

    await getEquipments(selectedOption.value);
  };

  const [selectEquipments, setSelectEquipments] = useState([
    { value: "", label: "" }
  ]);

  const getEquipments = async (id_empresa = null) => {
    if (!id_empresa) {
      return;
    }
    await api
      .get("/equipments/getEquipmentsForServiceCall?company_id=" + id_empresa)
      .then((r) => {
        let arr = [];

        arr.push(
          ...r.data.map((equip) => ({
            value: equip.id,
            label:
              equip?.equipment +
              " | " +
              equip?.brand?.name +
              " | " +
              equip?.model?.name
          }))
        );

        setSelectEquipments(arr);
      });

    setDisableEquipment(false);
  };

  const templateSelectPrime = (option) => {
    return <div style={{ maxWidth: "500px" }}>{option.label}</div>;
  };

  const handleChangeSelectEquipment = async (selectedOption) => {
    setEquipmentId(selectedOption.value);
    selectEquipments.forEach((e) => {
      if (e.value === selectedOption.value) {
        setEquipmentName(e.label);
        return;
      }
    });
  };

  return (
    <Dialog
      visible={visible}
      style={{ marginTop: 60, width: "80rem" }}
      header={"Ordem de serviço"}
      modal
      className="p-fluid"
      footer={footer}
      onHide={onClose}
    >
      <div className={"container pt-2"}>
        <Fade in={true}>
          <div className="container">
            <div className={"row"}>
              <div className="col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center">
                <form
                  className={
                    "d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center"
                  }
                >
                  <div className={"row pb-1"}>
                    <div className={"col-md-6 pb-3 d-flex flex-column"}>
                      <label htmlFor="Servico">Serviço:</label>

                      <Dropdown
                        value={serviceType}
                        options={servicoArr}
                        onChange={handleChangeServico}
                        style={{ height: "50px", alignItems: "center" }}
                      />
                    </div>

                    <div className={"col-md-6 pb-3 d-flex flex-column"}>
                      <label htmlFor="Empresa">Empresa: </label>
                      <Dropdown
                        value={companyName}
                        itemTemplate={templateSelectPrime}
                        valueTemplate={companyName}
                        options={empresas}
                        onChange={handleChangeSelect}
                        filter
                        optionLabel={"label"}
                        optionValue={"value"}
                        placeholder="Selecione a empresa"
                        style={{ height: "50px", alignItems: "center" }}
                      />
                    </div>
                  </div>
                  <div className={"row pb-1"}>
                    {context.selectedItem &&
                      Object.keys(context.selectedItem).length > 0 && (
                        <div className={"col-md-6 pb-3 d-flex flex-column"}>
                          <label htmlFor="Status">Status:</label>
                          <Dropdown
                            disabled={
                              !constants.masterEmails.includes(loggedEmail)
                            }
                            value={status}
                            options={statusArr}
                            onChange={(e) => setStatus(e.value)}
                            style={{ height: "50px", alignItems: "center" }}
                          />
                        </div>
                      )}

                    <div className={"col-md-6 pb-3 d-flex flex-column"}>
                      <label htmlFor="Empresa">Equipamento: </label>
                      <Dropdown
                        value={equipmentName}
                        itemTemplate={templateSelectPrime}
                        disabled={disableEquipment}
                        valueTemplate={equipmentName}
                        options={selectEquipments}
                        onChange={handleChangeSelectEquipment}
                        filter
                        optionLabel={"label"}
                        optionValue={"value"}
                        placeholder="Selecione um equipamento"
                        style={{ height: "50px", alignItems: "center" }}
                      />
                    </div>
                  </div>
                  <div className={"row pb-1"}>
                    <div className={"pb-3 d-flex flex-column col-md-4"}>
                      <label htmlFor="dataAtendimento">
                        Data do recebimento na oficina:
                      </label>
                      <Calendar
                        name={"dataAtendimento"}
                        onFocus={() => setWorkshopReceivedDate(new Date())}
                        value={workshopReceivedDate}
                        onChange={(e) => setWorkshopReceivedDate(e.value)}
                        mask="99/99/9999"
                      />
                    </div>
                    {serviceType !== "Frota" && (
                      <>
                        <div className={"col-md-4 pb-3"}>
                          <label htmlFor="invoice">Nota fiscal </label>
                          <InputText
                            name="invoice"
                            value={invoice}
                            autoComplete={"off"}
                            onChange={(e) => setInvoice(e.target.value)}
                          />
                        </div>

                        <div className={"col-md-4 pb-3"}>
                          <label htmlFor="invoice">Horimetro </label>
                          <InputText
                            name="hourMeter"
                            value={hourMeter}
                            onChange={(e) =>
                              setHourMeter(e.target.value.replace(/\D/g, ""))
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <div className={"row pb-1"}>
                    <div className={"col-md-12 pb-3 d-flex flex-column"}>
                      <label htmlFor="Descrição do problema">
                        Descrição do problema:
                      </label>
                      <InputTextarea
                        style={{ maxWidth: "100%", height: "100px" }}
                        value={errorDescription}
                        onChange={(e) => setErrorDescription(e.target.value)}
                      />
                    </div>
                  </div>
                  {serviceType !== "Frota" && (
                    <div className={"row pb-1"}>
                      <div className={"col-md-4 pb-3"}>
                        <label htmlFor="invoice">Telefone </label>
                        <InputText
                          name="phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className={"col-md-4 pb-3"}>
                        <label htmlFor="email">Email </label>
                        <InputText
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className={"col-md-4 pb-3"}>
                        <label htmlFor="email">
                          Reponsável pelo equipamento{" "}
                        </label>
                        <InputText
                          name="responsible"
                          value={responsible}
                          onChange={(e) => setResponsible(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="Descrição do problema">Observações:</label>
                    <InputTextarea
                      style={{ maxWidth: "100%", height: "50px" }}
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </div>

                  <div className={"row pb-1"}>
                    <div className={"col-md-6 pb-3 d-flex flex-column"}>
                      <label>Requisição:</label>
                      <InputTextarea
                        style={{ maxWidth: "100%", height: "50px" }}
                        value={request}
                        onChange={(e) => setRequest(e.target.value)}
                      />
                    </div>

                    <div className={"col-md-6 pb-3 d-flex flex-column"}>
                      <label htmlFor="Deadline">Previsão de entrega:</label>
                      <Calendar
                        name={"Deadline"}
                        onFocus={() => setDeadline(new Date())}
                        value={deadline}
                        onChange={(e) => setDeadline(e.value)}
                        mask="99/99/9999"
                      />
                    </div>
                  </div>

                  {context.selectedItem.status !== "Fechado" && (
                    <div
                      className={
                        "col-md-3 d-flex flex-column align-items-center justify-content-center"
                      }
                    >
                      <div className="form-check d-flex flex-row align-items-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultChecked={closeOs}
                          id="flexCheckDefault"
                          onChange={() => setCloseOs(!closeOs)}
                        />
                        <label
                          className="form-check-label ms-2"
                          htmlFor="flexCheckDefault"
                        >
                          Fechar OS
                        </label>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </Dialog>
  );
};

export default ServiceOrderForm;
