import React, { useEffect, useState } from "react";
import "./style.css";

import { Dialog } from "primereact/dialog";
import { useOrcamento } from "../../contexts/OrcamentoContext";
import api from "../../services/api";
import TableListOrcamentos from "./TableListOrcamentos";

export default function ListOrcamentos({ visible = true, onHide = () => {} }) {
  let [parts, setParts] = useState([]);

  const context = useOrcamento();

  useEffect(() => {
    if (!context.selectedItem || Object.keys(context.selectedItem) === 0) {
      setParts([]);
      return;
    }
    let orcamentoId = context.selectedItem.id;
    api.get(`/orcamento/${orcamentoId}`).then((response) => {
      setParts(response.data.orcamento_parts);
    });
  }, [context.selectedItem]);

  return (
    <>
      <Dialog
        visible={visible}
        style={{ marginTop: 60, width: "80rem" }}
        header={`Peças orçamento:`}
        modal
        className="p-fluid"
        onHide={() => onHide()}
      >
        <TableListOrcamentos parts={parts} />
      </Dialog>
    </>
  );
}
