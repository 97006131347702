import { useEffect } from "react";
import { formatToBrazilianCurrency } from "../../utils/masks";

export default function TableListOrcamentos({ parts }) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Preço</th>
          <th>Quantidade</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {parts.length > 0 ? (
          parts.map((part, index) => {
            return (
              <tr key={index}>
                <td>{part.description}</td>
                <td>{formatToBrazilianCurrency(part.price)}</td>
                <td>{part.quantity}</td>
                <td>{formatToBrazilianCurrency(part.price * part.quantity)}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="4">Nenhum dado disponível</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
